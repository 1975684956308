import config from '@cling/config'
import appLang from '@cling/language/locales/en/common'
import { brands } from '@cling/static'

const brand = brands[config.brand]

export default {
  brand,
  sign: 'sign',
  accept: 'accept',
  address: 'address',
  and: 'and',
  answerFeedback: {
    intermediate: {
      title: 'Thanks for sharing your thoughts.'
    },
    no: {
      start: {
        action: 'Submit',
        placeholder: 'What should we improve?',
        text: "Is there anything specific you'd like to share we can improve?",
        title: 'Thank you for sharing.'
      },
      success: {
        action: 'Learn more about $t(brand.name)',
        secondary: 'Go back to the document',
        text: 'Your feedback was sent perfectly. Our team will review your feedback with great care.',
        title: 'Thank you.'
      }
    },
    start: {
      no: 'No',
      title: 'Did you enjoy using $t(brand.name) to view this document?',
      yes: 'Yes'
    },
    yes: {
      action: 'Sign up for free',
      secondary: 'Learn more about $t(brand.name)',
      text: 'Create and send beautiful documents with $t(brand.name). Discover all the benefits of sending web-based documents with electronic signatures.',
      title: 'Get started using $t(brand.name) for free.'
    },
    custom: {
      title: 'Thank you.',
      text: 'Your feedback was sent perfectly. Our team will review your feedback with great care.',
      action: 'Go back to the document'
    }
  },
  answerModal: {
    answerSuccess: 'Your answer was sent perfectly.',
    bankId: {
      component: {
        defaultError: 'Something went wrong. Please try again.',
        signingInterrupted: 'Signing interrupted, try again...',
        startOnThisDevice: 'Start BankID on this device',
        validations: {
          socialNo: {
            personalNumber: 'Invalid social security number',
            required: 'Enter your social security number'
          }
        },
        viewText: {
          canceled: {
            text: 'Signing canceled. Please try again',
            title: 'Signing interrupted'
          },
          error: {
            text: 'Something went wrong. Please try again.',
            title: 'An error has occurred'
          },
          form: {
            text: 'Enter your social security number to begin signing of the agreement.',
            title: 'Sign the document with BankID'
          },
          new: {
            spinnerText: 'Scan the QR code on your device.',
            text: 'Open BankID on your device and sign the agreement.',
            title: 'Sign the document'
          },
          signing: {
            spinnerText: 'Sign the document.',
            text: 'Open BankID on your device and sign the agreement.',
            title: 'Signing in progress'
          },
          waiting: {
            spinnerText: 'Waiting for you to open BankID.',
            text: 'Open BankId on your device and sign the agreement.',
            title: 'Sign the document'
          }
        }
      },
      start: {
        action: 'Sign with BankID',
        text: 'Accept and sign the document with BankID.',
        title: 'Sign with BankID'
      },
      success: {
        text: '$t(answerModal.successText)',
        textFallback: '$t(answerModal.successTextFallback)',
        title: '$t(answerModal.successTitle)'
      }
    },
    decline: {
      component: {
        label: 'Comment (optional)'
      },
      start: {
        action: 'Decline',
        text: 'To decline this document please confirm by clicking the button below.',
        title: 'Decline Document'
      },
      success: {
        text: 'Thank you for leaving an answer.',
        textFallback: '$t(answerModal.decline.success.text)',
        title: "You've declined the document."
      }
    },
    signature: {
      component: {
        draw: {
          canvasLabel: 'Draw signature',
          tab: 'Draw'
        },
        subTitle: 'Choose sign method',
        terms:
          'By signing the document I agree that the electronic signature used is an representation of my handwritten signature - and is as valid.',
        text: {
          canvasLabel: 'Preview',
          nameLabel: 'Signed by',
          namePlaceholder: 'Type in your name',
          tab: 'Type'
        }
      },
      start: {
        action: 'Sign & Accept',
        text: '',
        title: 'Sign Document'
      },
      success: {
        text: '$t(answerModal.successText)',
        textFallback: '$t(answerModal.successTextFallback)',
        title: '$t(answerModal.successTitle)'
      }
    },
    versionError: {
      accept: { title: 'Could not sign the document' },
      decline: { title: 'Could not decline the document' },
      text: 'The document has been modified while you were viewing it. Please reload and review the new document in order to answer.',
      btn: 'Reload'
    },
    successText:
      "Once the document has been signed by all parties involved you'll recieve an email to {{email}} with a sealed copy of the document.",
    successTextFallback:
      'Once the document has been signed by all parties involved you can return to this webpage to download a sealed and completed copy of the document.',
    successTextFallbackSingle: 'The document is now signed and completed.',
    successTextSingle:
      'A confirmation email has been sent to {{email}} with a sealed copy of the document.',
    successTitle: "You've signed the document."
  },
  approved: 'signed',
  approx: 'approx.',
  cancel: 'Cancel',
  chapters: {
    answerButtons: {
      approveDescription:
        'We have informed {{- sender}} the document is approved.',
      approveTitle: 'The document is approved!',
      denyDescription:
        'We have informed {{- sender}} that you are not interested.',
      denyTitle: 'The document is declined',
      expiredTitle: 'The document has expired',
      expiredDescription:
        "The document's expiration date has passed. It's no longer possible to sign the document.",
      editPropDes: 'Edit property designation',
      enterPropDes: 'Enter property designation',
      inPersonSignatureHint:
        "You have been set to sign the document in person. If you'd like to sign the digital copy instead, please contact",
      waitingDescription: 'Waiting for {{remaining}} other to leave a reply.',
      youHave: 'You have '
    },
    articleItem: {
      eligibleForDeduction: 'Eligible for {{type}}-deduction'
    },
    articleList: {
      changeShowVatTooltip: 'Toggle to show {{type}}',
      title: 'Services / Products'
    },
    attachments: {
      emptyPlaceholder: 'No attachments added.'
    },
    packageGroup: {
      errorCallout: 'Select at least one option',
      selectableOptions: 'Selectable options'
    },
    packageItem: {
      add: 'Add option',
      select: 'Select option',
      selected: 'Selected'
    },
    parties: {
      allRiskAndLiability: 'Has all risk- and liability insurance',
      fTax: 'Certified for F-tax'
    },
    pricingSummary: {
      blocks: {
        approx: 'Approximate price',
        discount: 'Discount',
        deductionSubValue: 'Total price without deduction {{price}}',
        maxPrice: 'Max price',
        maxPriceVat: 'Max price {{vat}}',
        net: 'Net',
        price: 'Price',
        rounding: 'Rounding',
        subTitleArticles: 'Where products / services with fixed costs',
        summary: 'Summary',
        total: 'Total',
        typeDeduction: 'After {{type}}-deduction'
      },
      placeholder: {
        description:
          'Choose the option(s) which suits you best by clicking the ',
        descriptionBold: 'Select-button',
        title: 'Select an option'
      },
      terms: {
        houseWork:
          'Total price after tax deduction requires that performed work qualifies for tax deduction, that the buyer has enough amount of deduction available and that it has not been used for other work. The buyer are responsible to control this information. \n',
        houseWorkApprox:
          'Price is approximate. Remember that the price can alter with up to 15%. If services include ground- / demolition works or similar. Remember that the price might alter with up to 25%.',
        reverseVat: 'Note, reverse tax liability for construction work applies.'
      }
    },
    termsDisclaimer: {
      description: 'By approving this document you agree to the ',
      termsAndConditions: 'terms and conditions',
      title: 'Terms'
    }
  },
  close: 'Close',
  company: 'company',
  deduction: appLang.deduction,
  denied: 'declined',
  deny: 'Decline',
  denyModal: {
    denyButton: 'Decline document',
    helpfulInformation:
      "We'll inform {{- senderName}} at {{- senderCompany}} that you've declined the document.",
    otherPlaceholder: "Optional. Leave a comment on why you're declining.",
    subTitle: 'Confirm by clicking on the Decline-button.',
    successTitle: "You've declined the document",
    thanksAnswer: 'Thanks for your answer!',
    title: 'Decline document'
  },
  detail_plural: 'details',
  document: 'Document',
  document_plural: 'Documents',
  documentDetails: {
    subTitle: 'Information about the document.'
  },
  documentVoided: {
    title: 'The document is no longer available.',
    description: 'The sender has chosen to void the document.'
  },
  documentTemplateView: {
    emailTemplate: {
      expires: {
        content:
          '$t(documentTemplateView.reminder) $t(documentTemplateView.emailTemplate.first.content)',
        subject:
          '$t(documentTemplateView.reminder) $t(documentTemplateView.sharedSubject)',
        text: '$t(documentTemplateView.emailTemplate.expires.title) $t(documentTemplateView.emailTemplate.expires.subject). $t(documentTemplateView.readMoreAndReply)\n\n{{docLink}}',
        title: '$t(documentTemplateView.emailTemplate.first.title)'
      },
      first: {
        content:
          'You have received a document from {{company.name}}. Click on the link to read more and leave your answer.',
        subject: '$t(documentTemplateView.sharedSubject)',
        text: '$t(documentTemplateView.emailTemplate.first.title) $t(documentTemplateView.emailTemplate.first.subject). $t(documentTemplateView.readMoreAndReply)\n\n{{docLink}}',
        title: '$t(documentTemplateView.greetingName)'
      },
      reminder: {
        content:
          '$t(documentTemplateView.reminder) $t(documentTemplateView.emailTemplate.first.content)',
        subject:
          '$t(documentTemplateView.reminder) $t(documentTemplateView.sharedSubject)',
        text: '$t(documentTemplateView.emailTemplate.reminder.title) $t(documentTemplateView.emailTemplate.reminder.subject). $t(documentTemplateView.readMoreAndReply)\n\n{{docLink}}',
        title: '$t(documentTemplateView.emailTemplate.first.title)'
      },
      viewed: {
        content:
          '$t(documentTemplateView.reminder) $t(documentTemplateView.emailTemplate.first.content)',
        subject:
          '$t(documentTemplateView.reminder) $t(documentTemplateView.sharedSubject)',
        text: '$t(documentTemplateView.emailTemplate.viewed.title) $t(documentTemplateView.emailTemplate.viewed.subject). $t(documentTemplateView.readMoreAndReply)\n\n{{docLink}}',
        title: '$t(documentTemplateView.emailTemplate.first.title)'
      }
    },
    greetingName: 'Hello{{clientFirstName}}!',
    readMore: 'Read more:',
    readMoreAndReply: 'Read more & answer at:',
    reminder: 'Reminder!',
    sharedSubject: 'Document from {{company.name}} - {{document.data.name}}',
    smsContent:
      '{{company.name}} has sent a document to you that you can answer.',
    smsTemplate: {
      expires: {
        text: '$t(documentTemplateView.reminder)\n$t(documentTemplateView.smsContent) $t(documentTemplateView.readMore)\n\n{{docLink}}'
      },
      first: {
        text: '$t(documentTemplateView.greetingName)\n$t(documentTemplateView.smsContent) $t(documentTemplateView.readMore)\n\n{{docLink}}'
      },
      reminder: {
        text: '$t(documentTemplateView.reminder)\n$t(documentTemplateView.smsContent) $t(documentTemplateView.readMore)\n\n{{docLink}}'
      },
      viewed: {
        text: '$t(documentTemplateView.reminder)\n$t(documentTemplateView.smsContent) $t(documentTemplateView.readMore)\n\n{{docLink}}'
      }
    }
  },
  email: 'email',
  enterYour: 'Enter your {{thing}}',
  event: {
    accepted: 'Document signed by {{by}}.',
    denied: 'Document denied by {{by}}.',
    event_plural: 'events',
    expired: 'Document expired.',
    requestChange: 'Document manually marked as {{status}}.',
    sent: 'Document sent to {{to}}.',
    statusChanged: 'Document marked as {{status}}.',
    viewed: 'Has been viewed',
    viewedName: '{{name}} viewed the document.'
  },
  goBack: 'Go back',
  less: 'less',
  login: 'Login',
  logout: 'Logout',
  myPages: {
    docCallout: {
      form: {
        text: "You can view all previous documents you've recieved to your email through the document library.",
        title: 'Document library'
      },
      loginLink: 'login link',
      goToMyPagesTitle: 'Go to your pages of documents'
    },
    documentItem: {
      approved: 'Approved',
      expired: 'Expired'
    },
    documentsPlaceholder: "You haven't received any documents.",
    pageDescription: 'Here you will find all your received documents.',
    requestForm: {
      alreadySentDescription:
        'A login link was already sent to {{email}}. It may take a minute before you receive it.',
      alreadySentTitle: 'Login link already sent',
      emailFormat: 'Valid format: name@example.com',
      emailRequired: 'Enter your email',
      sendLink: 'Send login link',
      sentDescription: "We've sent a link to {{email}}. Use the link to login.",
      sentDescriptionSub:
        'For security reasons, you can only login via login link.',
      sentTitle: 'Login link sent to your inbox'
    },
    title: 'My pages'
  },
  name: 'name',
  orgNoShort: 'Co. registration number',
  participant_plural: 'participants',
  sender: 'sender',
  phone: 'phone',
  price: {
    approx: 'Approximate price',
    discount: 'Discount',
    fixed: 'Fixed price',
    markup: 'Markup',
    maxPrice: 'max price',
    net: 'Net total',
    openAcc: 'Open account',
    openAccMaxPrice: 'Open account with max price',
    openAccPrice: 'Open account',
    price: 'price',
    price_plural: 'prices',
    pricing: 'pricing',
    proposal: 'Package options',
    total: 'Total price'
  },
  print: 'Print{{thing,inlineSpace}}',
  download: 'Download{{thing,inlineSpace}}',
  reference: 'reference',
  replyBy: 'Reply by',
  see: 'see',
  select: 'Select{{thing,inlineSpace}}',
  send: 'send{{thing,inlineSpace}}',
  series: appLang.series,
  show: 'Show{{thing,inlineSpace}}',
  socialNo: 'social security number',
  status: {
    accepted: 'Signed',
    active: 'Active',
    archived: 'Archived',
    expired: 'Expired',
    voided: 'Voided',
    completed: 'Completed',
    denied: 'Denied',
    draft: 'Draft',
    new: 'Draft',
    semiSigned: 'Partially signed',
    sent: 'Sent',
    signed: 'Signed'
  },
  summaryBottom: {
    chooseFrom: 'Choose between selectable options',
    forDetails: 'for details',
    noOfOptions: '{{count}}',
    option: 'option',
    option_plural: 'options',
    reverseVat: 'VAT {{- amount}}. Reverse tax liability applies'
  },
  theDocument: 'the document',
  theSender: 'the sender',
  times: 'time',
  times_plural: 'times',
  total: 'total',
  tryAgain: 'Try again',
  unitType: appLang.unitType,
  vat: appLang.vat
}
