<script setup lang="ts">
import CSkeleton from '@cling/components/ui/Skeleton'
import BaseTextEditor from '@cling/components/ui/TextEditor'
import lang from '@cling/language'

import debounce from 'lodash/debounce'
import { computed, ref, watchEffect } from 'vue'

type Props = {
  value: string
  post: (text: string) => Promise<void>
  externalUpdatedAt?: string
  disabled?: boolean
  loading?: boolean
}

const { value, post, externalUpdatedAt } = defineProps<Props>()

const t = (key: string, params?: Record<string, unknown>) =>
  lang.t(`components:account.autoSaveNotes.${key}`, params)

const text = ref(value)
const updatedAt = ref<string | null>(null)
const isPosting = ref(false)

const updatedAgo = computed(() => {
  if (isPosting.value) return t('updated.posting')
  if (updatedAt.value) return t('updated.recent')
  if (externalUpdatedAt)
    return t('updated.ago', {
      time: lang.formatDate(externalUpdatedAt, 'p, dd MMM')
    })
  return ''
})

// Save function
const submit = async () => {
  isPosting.value = true
  await post(text.value)
  updatedAt.value = new Date().toISOString()
  isPosting.value = false
}

// Debounced save function
const debouncedSubmit = debounce(submit, 2000)

// Watch for value changes
watchEffect(() => {
  debouncedSubmit.cancel() // Cancel any pending debounced save
  text.value = value || ''
})

// Blur event cancels debounce and saves immediately
const handleBlur = () => {
  debouncedSubmit.cancel()
  submit()
}
</script>

<template>
  <div>
    <slot :updated-ago="updatedAgo" />
    <CSkeleton v-if="loading" height="10rem" />
    <BaseTextEditor
      v-else
      v-model="text"
      :disabled="disabled"
      :extensions="{ image: true }"
      :placeholder="t('placeholder')"
      textarea-style
      filled
      trigger-update
      @blur="handleBlur"
      @update:modelValue="debouncedSubmit"
    />
  </div>
</template>
