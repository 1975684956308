import config from '@cling/config'
import lang from '@cling/language'
import documentLang from '@cling/models/document/i18n'
import { convertPriceHeader } from '@cling/models/document/migrate'
import { createNodeTreeSchema } from '@cling/models/document/nodeUtils'
import defaultSubSchemas from '@cling/models/mockdata/defaultSubSchemas'
import Template from '@cling/models/template'
import {
  filterForbiddenProperties,
  formatPhone,
  getLatestEventString,
  getType
} from '@cling/utils'
import { getUsedPathsNested } from '@cling/utils/textTemplate'

import get from 'lodash/get'
import set from 'lodash/set'
import unset from 'lodash/unset'
import uniqId from 'uniqid'

import {
  aggregateDocumentPrices,
  calcPrices,
  filterEmptyProps,
  getVirtualFields,
  groupSelectedEvents,
  isVisibleOnlyNodes,
  setupFormSettings
} from './utils'

class PackageGroup {
  constructor({
    data,
    clients = [],
    useVat,
    rounding,
    deductionDate,
    currency
  }) {
    if (!data) throw new Error('Missing param data')
    this._data = data
    this._clients = clients
    this._useVat = useVat
    this._rounding = rounding
    this._deductionDate = deductionDate
    this._currency = currency
  }

  get data() {
    return this._data
  }
  get useVat() {
    return this._useVat
  }
  get rounding() {
    return this._rounding
  }
  get title() {
    return this._data.title
  }
  get type() {
    return this._data.type
  }
  get selectedPackages() {
    return this.packages.filter(pack => pack.isSelected) || []
  }
  get selectedArticles() {
    return this.selectedPackages.reduce(
      (res, { articles }) => [...res, ...articles],
      []
    )
  }

  get priceType() {
    return get(this, 'selectedPackages[0].prices.type', 'fixed')
  }
  get currency() {
    // Use document currency if no packages exist or unselected
    return get(this, 'selectedPackages[0].prices.currency') || this._currency
  }
  get maxTotal() {
    return this.priceType === 'openAccMaxPrice'
      ? this.selectedPackages.reduce(
          (sum, { prices }) => sum + prices.maxTotal,
          0
        )
      : null
  }

  get houseWorkManualAmount() {
    if (
      !this.selectedPackages.some(
        ({ prices }) =>
          get(prices, 'region.houseWorkManualAmount', null) !== null
      )
    )
      return null
    return this.selectedPackages.reduce((sum, { prices }) => {
      const { houseWorkManualAmount } = prices.region || {}
      return houseWorkManualAmount ? sum + houseWorkManualAmount : sum
    }, 0)
  }
  get prices() {
    return calcPrices({
      type: this.priceType,
      currency: this.currency,
      articles: this.selectedArticles,
      clients: this._clients,
      maxTotal: this.maxTotal,
      region: {
        country: 'sweden',
        houseWorkManualAmount: this.houseWorkManualAmount,
        deductionDate: this._deductionDate
      },
      useVat: this._useVat,
      rounding: this._rounding
    })
  }

  get packages() {
    return (
      this._data.packages.map(pack => ({
        ...pack,
        type: this.type,
        prices: calcPrices({
          type: get(pack, 'data.prices.type', 'fixed'),
          currency: get(pack, 'data.prices.currency') || this.currency, // Use document currency if unset pkg.data.prices
          articles: pack.articles,
          clients: pack.clients,
          maxTotal: get(pack, 'data.prices.maxTotal', null),
          region: {
            country: 'sweden',
            houseWorkManualAmount: get(
              pack,
              'data.prices.region.houseWorkManualAmount',
              null
            ),
            deductionDate: this._deductionDate
          },
          useVat: this._useVat,
          rounding: this._rounding
        })
      })) || []
    )
  }

  get articles() {
    return this.packages.reduce(
      (res, { articles }) => [...res, ...articles],
      []
    )
  }

  getPackageById(id) {
    return this.packages.find(({ packageId }) => packageId === id)
  }
}

// Middleware class to make access of class PackageGroup easier
class PackageGroups {
  constructor({
    data = {},
    articles = [],
    clients = [],
    useVat,
    rounding,
    deductionDate,
    currency
  }) {
    if (!data) throw new Error('Missing param data')
    this._data = Object.keys(data || {}).reduce(
      (res, key) => ({
        ...res,
        [key]: new PackageGroup({
          data: {
            ...data[key],
            packages: data[key].packages.map(p => ({
              ...p,
              articles: articles.filter(
                ({ packageId }) => packageId === p.packageId
              ),
              clients
            }))
          },
          clients,
          useVat,
          rounding,
          deductionDate,
          currency
        })
      }),
      {}
    )
  }
  get packages() {
    return Object.keys(this._data).reduce(
      (res, key) => [...res, ...this._data[key].packages],
      []
    )
  }
  get selectedPackages() {
    return this.packages.filter(({ isSelected }) => isSelected)
  }
  get selectedArticles() {
    return Object.keys(this._data).reduce(
      (res, key) => [...res, ...this._data[key].selectedArticles],
      []
    )
  }
  get selectedIds() {
    return Object.keys(this._data).reduce(
      (res, key) => [
        ...res,
        ...this._data[key].packages
          .filter(({ isSelected }) => isSelected)
          .map(({ packageId }) => packageId)
      ],
      []
    )
  }

  get groups() {
    return Object.keys(this._data).map(groupId => this._data[groupId])
  }
  get groupsPrices() {
    return (this.groups || []).map(({ prices }) => prices)
  }

  // Methods
  getPackageGroupById(id) {
    const uniqId = Object.keys(this._data).find(key =>
      this._data[key].packages.find(({ packageId }) => packageId === id)
    )
    return this._data[uniqId]
  }
  getPackageGroupUniqId(uniqId) {
    return this._data[uniqId] || {}
  }

  getPackageById(id) {
    const group = this.getPackageGroupById(id)
    return group ? group.getPackageById(id) : {}
  }
}

/**
 * Class to represent a document
 * Every template has basically consists of a template and data.
 */
class Document {
  /**
   * Template constructor
   * @param {Object} data
   * @param {Object} data.data The document data
   * @param {Object} data.template The template object
   */
  constructor(
    data,
    {
      computedPricing = true,
      isPreview = false,
      readPrices = true,
      props = {},
      settingsTheme = {}
    } = {}
  ) {
    if (!data) throw new Error('Missing param data')
    this._doc = data
    this._doc = convertPriceHeader(this._doc, settingsTheme)
    this._doc = setupFormSettings(this._doc)
    this._computedPricing = computedPricing
    this._theme = settingsTheme
    this._props = props
    this._readPrices = readPrices
    this._isPreview = isPreview
    this._pdfOptions = {}
    this._template = this._doc.template
      ? new Template(this._doc.template)
      : null
    this._dirtyKeys = {}
  }

  get data() {
    return this._doc.data
  }
  deleteProperty(path) {
    unset(this, `_doc.${path}`)
  }
  // Resolve order for receiver/sender titles:
  // edited value > settings value > default value
  get receiverString() {
    return (
      get(this._doc, 'data.receiverString') ||
      get(this._props, 'defaultReceiverString') ||
      lang.t('_common:receiver', {
        lng: this.language,
        postProcess: 'capitalize'
      })
    )
  }
  get senderString() {
    return (
      get(this._doc, 'data.senderString') ||
      get(this._props, 'defaultSenderString') ||
      lang.t('_common:sender', {
        lng: this.language,
        postProcess: 'capitalize'
      })
    )
  }

  get sender() {
    const customSender = get(this._doc, 'data.sender', {})

    return {
      company: {
        // TODO - Remove this conditional once the store is updated
        // TODO - Check getters for documents2/byId and forms/docById
        ...(this._doc.company && {
          publicId: this._doc.company.publicId,
          name: this._doc.company.name,
          orgNo: this._doc.company.organisationNumber,
          phone: this._doc.company.phone,
          logotype: this._doc.company.LogotypePublicId
            ? `${config.api.baseUrl}/file/${this._doc.company.LogotypePublicId}/download`
            : null,
          hasFSkatt: this._doc.company.fSkatt,
          hasInsurance: this._doc.company.insurance,
          region: this._doc.company.region
        }),
        ...filterEmptyProps(customSender.company)
      },
      user: {
        // TODO - Remove this conditional once the store is updated
        // TODO - Check getters for documents2/byId and forms/docById
        ...(this._doc.companyUser && {
          name: `${this._doc.companyUser.firstname || ''} ${this._doc.companyUser.lastname || ''}`.trim(),
          email: this._doc.companyUser.email,
          cellphone: this._doc.companyUser.cellphone,
          cellphoneRegion: this._doc.companyUser.cellphoneRegion,
          avatar: this._doc.companyUser.AvatarPublicId
            ? `${config.api.baseUrl}/file/${this._doc.companyUser.AvatarPublicId}/download`
            : null
        }),
        ...filterEmptyProps(customSender.user)
      }
    }
  }
  set sender(val) {
    // TODO What happens when this get posted since the store action will use what's inside this._doc
    // TODO When it posts to backend?
    if (val.company) this._doc.company = val.company
    if (val.user) this._doc.companyUser = val.user
  }
  get senderClient() {
    return this._doc.senderClient
      ? { ...this._doc.senderClient, isSender: true, _uniqueId: uniqId() }
      : null
  }
  get viewer() {
    if (!this._doc.viewerId) return null

    const viewer = this.clients.find(
      ({ publicDocumentId }) => publicDocumentId === this._doc.viewerId
    )
    return viewer
  }
  get remainingSignatures() {
    return this.clients
      .filter(({ documentRole }) =>
        ['signee', 'approver'].includes(documentRole)
      )
      .reduce((sum, client) => {
        // if (client.id === this.signee.id) return sum;
        const didAccept = get(client, 'answer.didAccept')
        return didAccept ? sum : sum + 1
      }, 0)
  }
  get answerActions() {
    const res = {
      canAnswer: false,
      hasAnswered: false,
      showActions: false,
      waitingForOthers: false
    }

    const hasAnswered = !!get(this.viewer, 'answer')
    const canAnswer =
      !!get(this.viewer, 'answerMethod.accept', false) &&
      ['signee', 'approver'].includes(get(this.viewer, 'documentRole'))
    const statusAllowsAnswer = ['sent', 'draft'].includes(this.status)

    if (canAnswer && statusAllowsAnswer && !hasAnswered) res.canAnswer = true
    res.showActions = canAnswer || this.isPreview
    res.hasAnswered = hasAnswered
    res.waitingForOthers = hasAnswered && statusAllowsAnswer

    return res
  }
  get id() {
    return this._doc.id || null
  }
  set id(value) {
    this._doc.id = value
  }
  get publicId() {
    return this._doc.publicId || null
  }
  get name() {
    return this.data.name || null
  }
  set name(value) {
    this.data.name = value
  }
  get articles() {
    return [
      ...this.packageGroups.selectedArticles,
      ...this.allArticles.filter(({ packageId }) => !packageId)
    ]
  }
  get allArticles() {
    return this._doc.articles || []
  }
  set allArticles(value) {
    this._doc.articles = value
  }
  get createdAt() {
    return this._doc.createdAt
  }
  get sentAt() {
    return this._doc.sentAt || null
  }
  get lastSentAt() {
    return this._doc.lastSentAt || null
  }
  get expiresAt() {
    return this.data.expiresAt || null
  }
  set expiresAt(value) {
    this.data.expiresAt = value
  }
  get acceptedAt() {
    return this._doc.acceptedAt || null
  }
  get projectId() {
    return this._doc.projectId || null
  }
  get docNumber() {
    return this._doc.docNumber || { series: 'document', value: null }
  }
  set docNumber(value) {
    this._doc.docNumber = value
  }
  get status() {
    return this._doc.status || null
  }
  get useSignOrder() {
    return this._doc.useSignOrder || false
  }
  get clients() {
    return this._doc.clients || []
  }
  set clients(value) {
    this._doc.clients = value
  }
  get recipients() {
    return [...(this.senderClient ? [this.senderClient] : []), ...this.clients]
  }
  get template() {
    return this._template
  }
  get isPreview() {
    return this._isPreview || false
  }
  get isHidden() {
    return this._doc.isHidden || false
  }
  get excludeFromStats() {
    return this._doc.excludeFromStats || false
  }
  get setPricesIncVat() {
    return get(this, 'data.formSettings.setPricesIncVat', null) && this.useVat
  } // Always return null if not yet defined, then we can setup defaults
  get formSettings() {
    return get(this, 'data.formSettings')
  }
  get useVat() {
    return this.formSettings.useVat
  }
  get rounding() {
    return this.formSettings.rounding
  }
  get vatType() {
    return this.formSettings.vatType
  }

  get rejectReasons() {
    const { rejectReasons } = this.data
    if (!rejectReasons) return null
    const type = getType(rejectReasons)
    if (type === 'array') return rejectReasons
    else if (type === 'object')
      return Object.keys(rejectReasons).map(k => rejectReasons[k]) // Fallback due to invalid backend
    return null
  }
  get personalMessage() {
    return this.data.personalMessage || null
  }
  get sourceDocument() {
    return this.data.sourceDocument || null
  }
  get reminders() {
    return this._doc.data.reminders || []
  }
  set reminders(value) {
    this._doc.data.reminders = value
  }
  set packageGroups(value) {
    this._doc.data.packageGroups = value
  }
  get packageGroups() {
    return new PackageGroups({
      data: this._doc.data.packageGroups,
      articles: this.allArticles,
      clients: this.clients,
      useVat: this.useVat,
      rounding: this.rounding,
      deductionDate: this.acceptedAt,
      currency: this.currency
    })
  }

  get events() {
    const { events = [] } = this._doc

    const filteredEvents = events.filter(
      event =>
        event.code !== 'statusChanged' ||
        ['accepted', 'denied'].includes(event.data.status)
    )
    const nrOfSignees = this.clients.filter(client =>
      ['signee', 'approver'].includes(client.documentRole)
    ).length

    for (const [i, event] of filteredEvents.entries()) {
      const nextEvent = filteredEvents[i + 1]

      if (!nextEvent) continue
      if (
        nextEvent.code === 'statusChanged' &&
        (nextEvent.data.status !== event.code || nrOfSignees <= 1)
      ) {
        filteredEvents.splice(i + 1, 1)
      }
    }
    // Finally group viewed and mailOpened events
    return groupSelectedEvents(filteredEvents, ['viewed', 'mailOpened'])
  }

  // Doc status getter with virtual statuses included
  // TODO: combine with regular status getter when all consequences are known
  get virtualStatus() {
    const { status, scheduledFirstDelivery } = this || {}
    if (status === 'draft' && scheduledFirstDelivery?.status === 'active')
      return 'scheduled'
    return status
  }

  get lastViewedAgo() {
    let updatedAt = null

    const { events = [] } = this._doc

    events
      ?.filter(event => event.code === 'viewed')
      ?.forEach(event => {
        if (!updatedAt || event.updatedAt > updatedAt)
          updatedAt = event.updatedAt
      })

    return updatedAt && getLatestEventString({ code: 'viewed', updatedAt })
  }

  get scheduledFirstDelivery() {
    const { reminders = [] } = this.data
    if (!reminders.length) return null
    return reminders.find(item => item.type === 'first') || null
  }

  get type() {
    return this.categoryTags[0]
  }
  get categoryTags() {
    return get(this._doc, 'tags', []).reduce((r, i) => [...r, i.name], [])
  }

  get language() {
    return get(this, '_doc.language', 'en')
  }
  set language(value) {
    // eslint-disable-next-line no-setter-return
    return set(this, '_doc.language', value)
  }

  get externalReferences() {
    return get(this, '_doc.externalReferences', [])
  }
  set externalReferences(value) {
    // eslint-disable-next-line no-setter-return
    return set(this, '_doc.externalReferences', value)
  }

  get currency() {
    return get(this, '_doc.currency')
  }

  get tags() {
    return get(this, '_doc.tags', [])
  }
  set tags(value) {
    // eslint-disable-next-line no-setter-return
    return set(this, '_doc.tags', value)
  }
  setTag(type, value) {
    const existingTag = this.tags.find(tag => tag.type === type)
    if (existingTag) existingTag.name = value
    else this.tags.push({ type, name: value })
  }

  get readPrices() {
    return this._readPrices
  }

  get prices() {
    const prices = aggregateDocumentPrices(this._doc)
    // TODO: filter some unwanted values here?
    return prices
  }

  // Will calculate for each packageGroup:
  // possible min and max price depending on group type (single|radio|checkbox)
  // then aggregate min/max for all groups
  get dynamicPrices() {
    const { groups } = this.packageGroups
    const result = { min: 0, max: 0 }

    ;(groups || []).forEach(
      ({ type, prices = {}, packages, articles, useVat, rounding }) => {
        const { total, type: priceType } = prices
        let groupResult = { min: total, max: total }

        if (packages && packages.length) {
          if (type === 'radio') {
            groupResult = packages.reduce(
              (res, { prices: pkgPrices }) => {
                if (res.min === null) res.min = pkgPrices.total
                if (pkgPrices.total > res.max) res.max = pkgPrices.total
                if (pkgPrices.total < res.min) res.min = pkgPrices.total
                return res
              },
              { ...groupResult, min: null }
            )
          } else if (type === 'checkbox') {
            const [toggleables, nonToggleables] = packages.reduce(
              ([t, n], c) => (c.toggable ? [[...t, c], n] : [t, [...n, c]]),
              [[], []]
            )

            if (toggleables.length) {
              const { maxTotal, houseWorkManualAmount } = packages.reduce(
                (res, { prices: pkgPrices }) => {
                  res.maxTotal += pkgPrices.maxTotal
                  res.houseWorkManualAmount += get(
                    pkgPrices,
                    'region.houseWorkManualAmount',
                    0
                  )
                  return res
                },
                { maxTotal: 0, houseWorkManualAmount: 0 }
              )

              const { total: totalAll } = calcPrices({
                type: priceType,
                articles,
                currency: this.currency,
                clients: this.clients,
                maxTotal,
                region: {
                  country: 'sweden',
                  houseWorkManualAmount,
                  deductionDate: this.acceptedAt
                },
                useVat,
                rounding
              })

              groupResult.max = totalAll
            }

            if (nonToggleables.length)
              groupResult.min = nonToggleables[0].prices.total
            else {
              groupResult.min = toggleables.reduce((min, p) => {
                if (min === null) min = p.prices.total
                else if (min > p.prices.total) min = p.prices.total
                return min
              }, null)
            }
          }
        }

        // Add result of each group to the final results for the whole document
        result.min += groupResult.min
        result.max += groupResult.max
      }
    )

    return result
  }

  set props(value) {
    this._props = value
  }

  get theme() {
    const defaultTheme = {
      background:
        'https://images.unsplash.com/photo-1468233748640-b31327627610?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&w=1280&q=80',
      backgroundFilter: null,
      showClingLogo: null,
      showPriceSummary: true,
      priceSummaryBackground: 'hsla(215, 14%, 34%, 1)',
      headingColor: 'hsla(215, 14%, 34%, 1)',
      fontFamily: null
      // TODO customerHideSantander,
      // TODO showWorkAddress,
      // TODO showTravel,
      // TODO showIsBinding,
      // TODO chapterPaymentSubtitle,
    }

    const customTheme = get(this._doc, 'data.theme', {})

    return { ...defaultTheme, ...this._theme, ...filterEmptyProps(customTheme) }
  }
  set theme(themeObject) {
    this._theme = themeObject
  }

  set pdfOptions(obj) {
    this._pdfOptions = obj
  }

  getAllNodesWithType(type) {
    const nodes = get(this, 'template.views.read.nodes') || []
    return nodes.filter(({ itemType }) => itemType === type)
  }

  get attachedFiles() {
    const fileObjects = []
    this.getAllNodesWithType('attachments').forEach(node => {
      const dataKey = node.value.attachments
      if (!dataKey) return
      const dataValues = get(this, dataKey)
      if (dataValues && Array.isArray(dataValues))
        fileObjects.push(...dataValues)
    })

    return fileObjects
  }

  // Document getter for readLayout (without any params)
  get readLayout() {
    return this.getReadLayout()
  }
  /**
   * Get the read layout for the document
   * @param {Object} obj Optional
   * @param {Boolean} obj.showHiddenNodes Optional if only nodes isVisible should be used, defaults to false
   * @returns {Object[]} Returns array of read nodes
   */
  getReadLayout({ showHiddenNodes = false } = {}) {
    if (!this.template) return []
    const data = {
      ...this.template.defaultData, // Check if this is still needed. If so spread it in the right places
      ...this._doc,
      data: {
        ...this._doc.data,
        ...(this.clients.length && {
          // Only show parties that are required
          clients: this.clients.filter(
            client => client.documentRole !== 'recipient'
          )
        }),
        ...(this._computedPricing && { prices: this.prices }), // Override pricing with getter
        sender: {
          ...this.sender.company,
          ...this.sender.user,
          // Conditionally spread email and cellphone to prevent overwrite with empty values
          name: this.sender.user.name,
          avatar: this.sender.user.avatar,
          ...(this.sender.user.email && { email: this.sender.user.email }),
          ...(this.sender.user.cellphone && {
            cellphone: this.sender.user.cellphone
          }),
          type: 'company',
          companyName: this.sender.company.name
        },
        receiverString: this.receiverString,
        senderString: this.senderString
      }
    }
    const layout = createNodeTreeSchema({
      data,
      tree: this.template.views.read.nodes,
      bindData: true,
      customProps: this._props,
      pdfOptions: this._pdfOptions
    })

    const nodes = [...layout]

    if (showHiddenNodes) return nodes

    return isVisibleOnlyNodes({
      nodes,
      data
    })
  }

  get fields() {
    return {
      ...getVirtualFields(this._doc),
      ...this._doc.data.fields
    }
  }
  get textTemplateMap() {
    // rawData-path | ui-label

    const { fields } = this

    return Object.keys(fields).reduce(
      (res, key) => ({
        ...res,
        [fields[key].key]: fields[key].label
      }),
      {}
    )
  }
  get textTemplateItems() {
    // Array with document variables which will be displayed in read-view
    // as document-specific value
    const { fields } = this
    return Object.values(fields).reduce(
      (res, field) => [
        ...res,
        {
          id: field.key,
          label: field.label,
          tooltip: get(this._doc, field.key),
          filter: field.filter
        }
      ],
      []
    )
  }
  get usedFields() {
    return getUsedPathsNested(this.data)
  }

  get hasAnswers() {
    return this.clients
      .filter(({ documentRole }) =>
        ['signee', 'approver'].includes(documentRole)
      )
      .some(({ answer }) => !!answer)
  }

  // Method to remove answer data from the document instance
  removeAnswer() {
    this._doc.acceptedAt = null
    this._doc.status = 'draft'

    // Remove answer for each client
    this.clients.forEach(client => {
      client.answer = null
    })
  }

  /**
   * Set answer method for this document
   * At least one of accept or deny is required
   * @param {Object} obj
   * @param {String} obj.accept Optional string how to accept
   * @param {String} obj.deny Optional string how to deny
   */
  setAnswerMethod({ accept, deny }) {
    if (typeof accept === 'undefined' && typeof deny === 'undefined')
      throw new Error('Missing param accept or deny')
    this.removeAnswer()
    if (accept) {
      set(this, '_doc.data.defaultAnswerMethod.accept', accept)
      set(this, '_doc.template.defaultAnswerMethod.accept', accept)
    }
    if (deny) {
      set(this, '_doc.data.defaultAnswerMethod.deny', deny)
      set(this, '_doc.template.defaultAnswerMethod.deny', deny)
    }

    this.clients
      .filter(({ documentRole }) =>
        ['signee', 'approver'].includes(documentRole)
      )
      .forEach(client => {
        client.answer = null
        if (accept) client.answerMethod.accept = accept
        if (deny) client.answerMethod.accept = accept
      })
  }

  resetReminders() {
    const { template } = this
    const { reminders: defaultReminders } = template
    this.reminders = defaultReminders
  }
  resetDefaultSelected() {
    const rawGroups = this._doc.data.packageGroups
    if (!rawGroups) return
    this.packageGroups = Object.keys(rawGroups).reduce(
      (res, id) => ({
        ...res,
        [id]: {
          ...rawGroups[id],
          packages: (rawGroups[id].packages || []).map(pack => ({
            ...pack,
            isSelected:
              pack.isSelectedDefault || rawGroups[id].type === 'single'
          }))
        }
      }),
      {}
    )
  }

  /**
   * Clone a document, returns a identical copy
   * @returns {Document} Returns a exact deep clone of the document
   */
  clone() {
    const instance = this
    return Object.assign(
      // Set the prototype of the new object to the prototype of the instance.
      // Used to allow new object behave like class instance.
      Object.create(Object.getPrototypeOf(instance)),
      // Prevent shallow copies of nested structures like arrays, etc
      JSON.parse(JSON.stringify(instance))
    )
  }

  /**
   * Duplicate this document
   * Returns a copy of the document filtered by params
   * @returns {Document}
   */
  duplicate() {
    const newDocument = this.clone()
    newDocument.id = null
    newDocument._doc._id = null // TODO: We should consider stop using _id everywhere
    newDocument.deleteProperty('externalReferences')
    newDocument.deleteProperty('data.expiresAt') // Delete, setting to null would cause schema to not validate
    newDocument.deleteProperty('data.rejectReasons')
    newDocument.deleteProperty('company')
    newDocument.deleteProperty('companyUser')
    newDocument.deleteProperty('senderClient')
    newDocument.clients = newDocument.clients.map(obj =>
      filterForbiddenProperties(obj, [
        'id',
        '_id',
        'publicDocumentId',
        'answer',
        'customDocLink'
      ])
    )
    newDocument.allArticles = newDocument.allArticles.map(obj =>
      filterForbiddenProperties(obj, [
        'id',
        '_id',
        'createdAt',
        'updatedAt',
        'deletedAt'
      ])
    )
    newDocument.resetReminders()
    newDocument.resetDefaultSelected()
    return newDocument
  }

  copyTemplateDocDefault({ keepClients = false } = {}) {
    const {
      template,
      articles,
      data,
      tags,
      docNumber,
      language,
      currency,
      clients,
      useSignOrder
    } = this.duplicate().getRawData()

    Object.keys(data).forEach(key => {
      if (get(template, `validationSchema.properties.data.properties.${key}`)) {
        set(
          template,
          `validationSchema.properties.data.properties.${key}.default`,
          data[key]
        )
      } else if (Object.keys(defaultSubSchemas).includes(key)) {
        // Data keys allowed upsert privileges in validationSchema
        set(template, `validationSchema.properties.data.properties.${key}`, {
          ...defaultSubSchemas[key],
          default: data[key]
        })
      }
    })

    const rootDefault = {
      articles,
      tags,
      docNumber,
      currency,
      useSignOrder
    }
    delete docNumber.value // Never allow a specific docNumber value to be set as default

    if (keepClients) {
      rootDefault.clients = clients
    } else {
      unset(template, 'validationSchema.properties.clients')
    }

    Object.keys(rootDefault).forEach(key => {
      if (rootDefault[key])
        set(
          template,
          `validationSchema.properties.${key}.default`,
          rootDefault[key]
        )
    })

    // Set template language from document
    template.language = language
    return template
  }

  /**
   * Note: A copy of this logic exists in the action DO_SAVE_DYNDOC as we no longer have access to the instance there
   */
  getRawData() {
    return {
      ...this._doc,
      data: {
        ...this._doc.data,
        prices: this.prices,
        name: this.name || ''
      }
    }
  }

  // Getter for PDF-verification condition
  get allowVerification() {
    return (
      this.status === 'accepted' ||
      this.clients.some(
        ({ answerMethod }) => answerMethod && answerMethod.accept === 'inPerson'
      )
    )
  }

  /**
   * Format a phone for this document, uses company.region fallback of no region was specified
   * which will be the case for old clients.
   * @param {String} phone
   * @param {Object} obj Optional
   * @param {String}obj.defaultRegion Optional region to use, recommended to use client.cellphoneRegion
   * @returns {Promise<Object|null>} Returns a formatted phone object or null
   */
  async formatPhone(phone, { defaultRegion } = {}) {
    return formatPhone(phone, {
      defaultRegion: defaultRegion || this.sender.company.region
    })
  }

  // Form utility for tracking dirty doc props for the edit session
  setDirty(key) {
    this._dirtyKeys[key] = true
  }
  isDirty(key) {
    return !!this._dirtyKeys[key]
  }

  /**
   * Document translation wrapper, can be used to automatically use document specifics
   * Uses document language by default
   * @param {String} key Translation key
   * @param {Object} optionsParam Optional translations options
   * @param {Object} extraOptions Optional extras
   * @param {String} extraOptions.fnType Optional which translate function to use, defatuls to t (etc tc, te)
   * @returns {String}
   */
  $t(key, optionsParam = {}, { fnType = 't' } = {}) {
    const options = {
      lng: this.language, // When translation with document $t use doc language by default
      ...optionsParam
    }

    // Automatically show correct vat
    const vatKeys = ['exVat', 'incVat', 'vat.exVat', 'vat.incVat'] // Translation keys to insert 'thing' for interpolation
    const parsedKey = key && key.includes(':') ? key.split(':')[1] : key // Support for both direct or namespaced keys
    if (parsedKey === 'vat')
      return documentLang[fnType](`vat.${this.vatType}`, { ...options }) // Always allow us to use _doc.$t('vat') to get current vat type translated
    if (vatKeys.includes(parsedKey))
      options.thing = documentLang[fnType](`vat.${this.vatType}`, options)

    return documentLang[fnType](key, { ...options })
  }

  // Wrapper to allow translateCount
  $tc(key, optionsParam = {}) {
    return this.$t(key, optionsParam, { fnType: 'tc' })
  }
}

export default Document
