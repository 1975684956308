import clingAPI, {
  companyUser as $companyUser,
  extensionApi,
  loginAuthToken,
  register,
  setNewPassword,
  storePublicConsent
} from '@cling/api'
import config from '@cling/config'
import { viewContext } from '@cling/globalState'
import lang from '@cling/language'
import rollbar from '@cling/services/error/rollbar'
import { global } from '@cling/store/action-types'
import { getDeviceTypeAndMode } from '@cling/utils'
import webStorage from '@cling/utils/webStorage'

import get from 'lodash/get'

import { actionTypes, mutationTypes } from '../constants'

const {
  UPDATE_VIEW_SETTINGS,
  LOAD_USER_POSITION,
  LOAD_USER,
  LOGIN_USER,
  LOGIN_AUTH_TOKEN,
  AUTH_3RD_PARTY_USER,
  SWITCH_CONNECTED_USER,
  LOGOUT_USER,
  REGISTER_USER,
  RESET_USER_PASSWORD
} = actionTypes

const {
  DO_LOAD_CURRENT_COMPANY,
  DO_LOAD_ALL_SETTINGS,
  DO_NORMALIZE_COMPANY_USERS,
  LOAD_UNREAD_NOTIFICATIONS
} = global

const { SET_USER_LOCATION, SET_USER } = mutationTypes

const getCodeTranslation = code =>
  lang.te(`errors:${code}`) && lang.t(`errors:${code}`)

export default {
  async [LOAD_USER]({ commit, getters, dispatch }) {
    try {
      // Check if there is a token in store
      if (!webStorage.getItem('token')) {
        webStorage.removeItem('tokenExpire')
        webStorage.removeItem('refreshToken')

        commit(SET_USER, {
          userId: null,
          companyId: null
        })

        this.$router.push({ name: 'Login' })
        return
      }

      const { data: companyUserData } = await clingAPI.get('/companyUser/self')

      let connectedUsers = []

      // Only fetch connected accounts if not on a slave account that is not yet verified
      if (!(companyUserData.isSlave && !companyUserData.verifiedEmailAt)) {
        ;({ data: connectedUsers } = await $companyUser.getConnectedUsers())
      }

      const {
        id: userId,
        CompanyId: companyId,
        language,
        locale
      } = companyUserData

      // Override instance lang+locale with user preference
      if (language) lang.changeLanguage(language)
      if (locale) lang.changeLocale(locale)

      await this.dispatch(DO_NORMALIZE_COMPANY_USERS, {
        companyUsers: [
          {
            ...companyUserData,
            connectedUsers
          }
        ]
      })

      commit(SET_USER, {
        userId,
        companyId
      })

      await this.dispatch(DO_LOAD_CURRENT_COMPANY)
      await this.dispatch(DO_LOAD_ALL_SETTINGS)

      if (
        this.hasModule('notifications') &&
        !this.getters['notifications/isFetching']
      ) {
        this.dispatch(LOAD_UNREAD_NOTIFICATIONS, { throttleTime: 5 })
      }

      const { user } = getters

      if (user) {
        // Update Rollbar with authenticated user data
        let username = ''
        if (user.firstname || user.lastname) {
          username = user.fullName
        }
        rollbar.configure({
          payload: {
            person: {
              id: user.id, // required
              username: username || null,
              email: user.email || null
            }
          }
        })

        // Sync user on chat service
        this.$supportChat?.syncData()

        // Set companyUserId on for eventTracker
        this.$trackEvent?.('loadUser', { companyUserId: user.id })
      }
    } catch (err) {
      this.handleError(err, {
        action: LOAD_USER,
        actionPayload: null
      })

      // Logout the user as something was very wrong
      dispatch(LOGOUT_USER)
    }
  },

  /**
   * @name LOGIN_USER
   * Login a user with email and password
   *
   * @param {Object} Vuex object
   * @param {Object} obj
   * @param {String} obj.email
   * @param {String} obj.password
   * @param {Boolean} obj.doRedirect Optional if user should be redirected after login, defaults to true
   * @param {Boolean} obj.doLoadUser Optional if user should be loaded into store after login, defaults to true
   * @param {Boolean} obj.superUserId Optional if auth as employee but as user by this id
   *
   * @returns {Promise<Boolean>} Promise that resolves with true if successfully, otherwise false
   */
  async [LOGIN_USER](
    { commit, dispatch },
    {
      email,
      password,
      doRedirect = true,
      doLoadUser = true,
      superUserId = null,
      responseType = null,
      code = null,
      allowCodeSignup = true
    }
  ) {
    try {
      commit(SET_USER, { isLoggingIn: true })
      dispatch(UPDATE_VIEW_SETTINGS, {
        view: 'login',
        settings: { error: null }
      })

      let data
      if (superUserId) {
        // Auth as employee, but as user by id
        ;({ data } = await clingAPI.post('/auth/employee', {
          email,
          password,
          CompanyUserId: superUserId
        }))
      } else if (code) {
        ;({ data } = await clingAPI.post('/oauth2/token', {
          code,
          client_id: config.clientId,
          grant_type: 'authorization_code',
          brand: config.brand,
          allowCodeSignup,
          ...getDeviceTypeAndMode(viewContext.value)
        }))
        // Convert to our expire format
        const tokenExpires = new Date()
        tokenExpires.setSeconds(tokenExpires.getSeconds() + data.expires_in)
        // Convert to correct destruct format as below
        data = {
          token: data.access_token,
          tokenExpires: tokenExpires.toISOString(),
          refreshToken: data.refresh_token
        }
      } else {
        ;({ data } = await clingAPI.post('/auth/companyUser', {
          email,
          password,
          response_type: responseType,
          ...getDeviceTypeAndMode(viewContext.value)
        }))
      }

      if (responseType === 'code' && data.code) {
        commit(SET_USER, { isLoggingIn: false })
        return data
      }

      const { token, tokenExpires, refreshToken } = data

      webStorage.setItem('token', token)
      webStorage.setItem('tokenExpires', tokenExpires)
      webStorage.setItem('refreshToken', refreshToken)

      if (doLoadUser) {
        await dispatch(LOAD_USER)
      }

      // We allow LOGIN_USER without redirect
      if (!doRedirect) return true

      const { redirect } = this.state.route.query || {}

      if (redirect) {
        this.$router.push(redirect)
        return true
      }
      this.$router.push({ name: 'account' })
      return true
    } catch (err) {
      let error = lang.t('errors:fallback')

      let wrongCredentials = false
      if (err && typeof err.getPayload === 'function') {
        const payload = err.getPayload()
        if (payload && payload.axios && payload.axios.status === 401) {
          wrongCredentials = true
        }
      }
      if (wrongCredentials) {
        error = lang.t('actions:loginUser.wrongCredentials')
      }

      // Is there a specific code we can use to show a even better message?
      if (err && typeof err.getPayload === 'function') {
        const payload = err.getPayload()
        if (payload?.code && lang.te(`errors:${payload.code}`)) {
          error = lang.t(`errors:${payload.code}`)
        }
      }

      dispatch(UPDATE_VIEW_SETTINGS, {
        view: 'login',
        settings: { error }
      })

      commit(SET_USER, { isLoggingIn: false })

      if (!wrongCredentials) {
        // Don't add payload as this will contain user credentials
        this.handleError(err, {
          action: LOGIN_USER,
          actionPayload: null
        })
      }
      return false
    } finally {
      commit(SET_USER, { isLoggingIn: false })
    }
  },

  /**
   * @name LOGIN_AUTH_TOKEN
   * Login a user with a one time use authToken. Used when clicking a email link etc and we want to auto login the user.
   *
   * @param {Object} Vuex object
   * @param {Object} obj
   * @param {String} obj.token
   * @param {String} obj.to Vue router to object
   *
   * @returns {Promise} Promise that resolves if successfully
   */
  async [LOGIN_AUTH_TOKEN](
    { commit, dispatch },
    { authToken, to, doRedirect = true }
  ) {
    let query = {}
    try {
      if (!authToken) throw new Error('Missing param authToken')
      if (!to && doRedirect) throw new Error('Missing param to')

      commit(SET_USER, { isLoggingIn: true })

      // Remove authToken from the original query so we dont try to use it again, as it will fail next time as well
      if (to && viewContext.value !== 'pipedrive') {
        query = Object.assign({}, to.query)
        delete query.authtoken
      }

      const { data } = await loginAuthToken(authToken, {
        ...getDeviceTypeAndMode(viewContext.value)
      })
      const { token, tokenExpires, refreshToken } = data

      webStorage.setItem('token', token)
      webStorage.setItem('tokenExpires', tokenExpires)
      webStorage.setItem('refreshToken', refreshToken)

      // Reload user
      await dispatch(LOAD_USER)

      if (!doRedirect) return true

      this.$router.push({ path: to.path, query })
    } catch (err) {
      if (to?.path) query.redirect = to?.path
      await dispatch(LOGOUT_USER, { next: { name: 'Login', query } })

      // Don't add payload as this will contain user credentials
      this.handleError(err, {
        action: LOGIN_AUTH_TOKEN,
        actionPayload: null
      })
    } finally {
      commit(SET_USER, { isLoggingIn: false })
    }
  },

  async [AUTH_3RD_PARTY_USER]({ commit, dispatch }, { service, code }) {
    commit(SET_USER, { isLoggingIn: true })

    const result = {
      isAuth: false,
      isVerify: false,
      company: null,
      companyUser: null,
      addedToExistingCompanyId: null,
      error: null
    }

    try {
      // TODO: will maybe be differing routes depending on login/register
      const { data } = await extensionApi.signupWithCrm(service, {
        code,
        // password, // Optional choose a password, or generate and re-use to allow to be set in the next view
        doLogin: true,
        ...getDeviceTypeAndMode(viewContext.value),
        brand: config.brand
      })

      const { addedToExistingCompanyId, authenticate } = data

      const { token, tokenExpires, refreshToken, company, companyUser } =
        authenticate

      webStorage.setItem('token', token)
      webStorage.setItem('tokenExpires', tokenExpires)
      webStorage.setItem('refreshToken', refreshToken)

      if (companyUser && companyUser.isSlave && !companyUser.verifiedEmailAt) {
        result.companyUser = companyUser
        result.isVerify = true
        return result
      }

      // Reload user
      await dispatch(LOAD_USER)

      if (addedToExistingCompanyId)
        result.addedToExistingCompanyId = addedToExistingCompanyId
      result.company = company
      result.isAuth = true

      return result
    } catch (err) {
      this.handleError(err, {
        action: AUTH_3RD_PARTY_USER,
        actionPayload: null
      })
      return {
        isAuth: false,
        isVerify: false,
        company: null,
        companyUser: null,
        addedToExistingCompanyId: null,
        error: err.message
      }
    } finally {
      commit(SET_USER, { isLoggingIn: false })
    }
  },
  /**
   * @name SWITCH_CONNECTED_USER
   * Change account for the authenticated user
   *
   * @param {Object} Vuex object
   * @param {Object} obj
   * @param {Number} obj.companyUserId The userId the user wish to change to
   * @param {Boolean} obj.doRedirect Optional if the user should be redirected to account after switch (default true) or just reload current page
   * @returns {Promise<>}
   */
  async [SWITCH_CONNECTED_USER](
    { commit, dispatch },
    { companyUserId, doRedirect = true }
  ) {
    try {
      if (typeof companyUserId === 'undefined')
        throw new Error('Missing param companyUserId')
      commit(SET_USER, { isLoggingIn: true })
      const { data } = await $companyUser.getConnectedUserAuth(companyUserId)
      const { token, tokenExpires, refreshToken } = data

      webStorage.setItem('token', token)
      webStorage.setItem('tokenExpires', tokenExpires)
      webStorage.setItem('refreshToken', refreshToken)
      // Reset state
      this.commit('reset')

      await dispatch(LOAD_USER)
      if (doRedirect) this.$router.push({ name: 'account' })
      else window.reloadApp?.(true)
    } catch (err) {
      this.handleError(err, {
        action: SWITCH_CONNECTED_USER,
        actionPayload: null
      })
    } finally {
      commit(SET_USER, { isLoggingIn: false })
    }
  },
  /**
   * @name REGISTER_USER
   * Register a new account
   * Creates a new companyUser and company, stores consent and authenticate the user
   *
   * @param {Object} Vuex object
   * @param {Object} obj
   * @param {Object} obj.company The company data with name
   * @param {Object} obj.companyUser The companyUser with email and password
   * @param {String} obj.termsVersion Version of the terms that is accepted
   * @param {String} obj.voucherCode Optional voucherCode to use
   * @param {String} obj.source Optional which source to track for the user
   * @param {Boolean} obj.throwIfSlaveUser Optional if error should be thrown if the new user is a slave user, defaults to false
   *
   * @returns {Promise<Object>} Resolves with object if successfully signup and is now logged in:
   *                            { isAuth: true, // True if the uses was successfully logged in
   *                              isVerify: false, // True if the user already exist and must very their account by email
   *                              didNavigate: false, // True if this action sent the user to some route
   *                             }
   */
  async [REGISTER_USER](
    { commit, dispatch },
    {
      company,
      companyUser,
      termsVersion,
      voucherCode = null,
      source = null,
      region,
      language,
      currency,
      throwIfSlaveUser
    }
  ) {
    try {
      const result = {
        isAuth: false,
        isVerify: false,
        didNavigate: false
      }

      dispatch(UPDATE_VIEW_SETTINGS, {
        view: 'register',
        settings: { error: null }
      })
      commit(SET_USER, { isRegistering: true })

      if (!company) throw new Error('Missing param company')
      if (!companyUser) throw new Error('Missing param companyUser')
      if (!termsVersion) throw new Error('Missing param termsVersion')

      const { data } = await register({
        Company: company,
        CompanyUser: companyUser,
        voucherCode,
        source,
        region,
        language,
        currency,
        throwIfSlaveUser
      })

      const { /* Company: newCompany, */ CompanyUser: newCompanyUser } = data

      const consentData = {
        type: 'terms',
        version: termsVersion,
        identifier: newCompanyUser.email,
        object: 'CompanyUser',
        objectId: newCompanyUser.id
      }
      // Store consent
      await storePublicConsent(consentData)

      // Is this a slave companyUser? The user must verify email.
      if (newCompanyUser.isSlave) {
        result.isVerify = true
        return result
      }

      // Log in user
      const didLogin = await dispatch(LOGIN_USER, {
        email: companyUser.email,
        password: companyUser.password,
        doRedirect: false
      })
      result.isAuth = didLogin
      if (didLogin) {
        // eventTracker event for registration
        this.$trackEvent?.('register')
      }

      return result
    } catch (err) {
      // Let caller handle error if they wish to
      if (get(err, 'payload.code') === 4008 && throwIfSlaveUser) throw err

      if (err && err.code) {
        const message = getCodeTranslation(err.code)
        if (message) {
          dispatch(UPDATE_VIEW_SETTINGS, {
            view: 'register',
            settings: { error: message }
          })
        }
      }
      this.handleError(err, {
        action: REGISTER_USER,
        actionPayload: null
      })
      return {
        isAuth: false,
        isVerify: false,
        didNavigate: false
      }
    } finally {
      commit(SET_USER, { isRegistering: false })
    }
  },

  /**
   * @name RESET_USER_PASSWORD
   * Choose a new password with token and authenticates user
   *
   * @param {Object} Vuex object
   * @param {Object} obj
   * @param {String} obj.password New user password
   * @param {String} obj.token Token to reset the password
   * @param {Boolean} obj.doRedirect Optional if the user should be redirected, defaults to true
   *
   * @returns {Promise<Boolean>} Resolves with true if successfully, otherwise false
   */
  async [RESET_USER_PASSWORD](
    { commit, dispatch },
    { password, token, doRedirect = true }
  ) {
    try {
      dispatch(UPDATE_VIEW_SETTINGS, {
        view: 'register',
        settings: { error: null }
      })
      commit(SET_USER, { isResettingPassword: true })

      if (!password) throw new Error('Missing param password')
      if (!token) throw new Error('Missing param token')

      const { data: companyUser } = await setNewPassword(password, token)
      const { email } = companyUser

      // Start auto login user
      await dispatch(LOGIN_USER, { email, password, doRedirect })
      return true
    } catch (err) {
      if (err && err.code) {
        const message = getCodeTranslation(err.code)
        if (message) {
          dispatch(UPDATE_VIEW_SETTINGS, {
            view: 'register',
            settings: { error: message }
          })
        }
      }
      this.handleError(err, {
        action: RESET_USER_PASSWORD,
        actionPayload: null
      })
      return false
    } finally {
      commit(SET_USER, { isResettingPassword: false })
    }
  },

  /**
   * @name LOGOUT_USER
   * Log out the user, will redirect to log in or use next param
   *
   * @param {Object} Vuex object
   * @param {Object} obj Optional
   * @param {Object} obj.next Vue-router route obj (optional)
   */
  [LOGOUT_USER]({ commit }, { next = { name: 'Login' } } = {}) {
    try {
      webStorage.removeItem('token')
      webStorage.removeItem('tokenExpires')
      webStorage.removeItem('refreshToken')

      this.commit('reset')
    } finally {
      commit(SET_USER, {
        isLoggingIn: false,
        userId: null,
        companyId: null
      })
    }

    if (next) {
      try {
        return this.$router.replace(next)
      } catch (err) {
        this.handleError(err, {
          action: LOGOUT_USER,
          actionPayload: null
        })
        return this.$router.replace({ name: 'Login' })
      }
    }

    return true
  },
  /**
   * Reload the current user position and set it to the store
   * @param {Object} param0 Vuex store object
   */
  [LOAD_USER_POSITION]({ commit }) {
    navigator.geolocation.getCurrentPosition(({ coords }) => {
      commit(SET_USER_LOCATION, [coords.longitude, coords.latitude])
    })
  }
}
