import lang from '@cling/language'
import get from 'lodash/get'

const $title = key => lang.t(`components:document.chapters.${key}.title`)
const $subTitle = key =>
  lang.t(`components:document.chapters.subChapters.${key}.title`)
const mappedComponentNames = {
  // Based on folder name in document blocks as object key
  // and node itemType in array values
  docChapter: ['section'],
  docChapterSub: ['subSection'],
  docParties: ['party'],
  workAddressText: ['workAddressText'],
  textContent: ['html'],
  packageGroup: ['selectionGroup'],
  pricingSummary: ['pricingSummary'],
  multiGroupPricing: ['multiGroupPricing'],
  estimatedTimeToCompleteText: ['estimatedTimeToCompleteText'],
  estimatedWorkStartAndEndAtText: ['estimatedWorkStartAndEndAtText'],
  extraWorkTimeImpactText: ['extraWorkTimeImpactText'],
  extraWorkConstructionPricingList: ['extraWorkConstructionPricingList'],
  notBinding: ['notBinding'],
  terms: ['termsDisclaimer'],
  attachments: ['attachments'],
  presentation: ['presentation'],
  coverBlock: ['coverBlock'],
  header: ['header'],
  answer: ['answer']
}

const defaultComponentsProperties = () => ({
  docChapter: {
    parties: {
      title: $title('parties'),
      classList: 'print-avoid-break-inside'
    },
    workPackage: { title: $title('workPackage') },
    warningInfo: { title: $title('warningInfo') },
    estimatedTime: { title: $title('estimatedTime') },
    extraWorkTimeImpact: { title: $title('extraWorkTimeImpact') },
    extraWorkConstructionPricingList: {
      title: $title('extraWorkConstructionPricingList')
    },
    paymentTerms: { title: $title('paymentTerms') },
    misc: { title: $title('misc') }
  },
  docChapterSub: {
    workAddress: { title: $subTitle('workAddress'), hideFromEdit: true },
    description: { title: $subTitle('description') },
    travelCosts: { title: $subTitle('travelCosts') },
    attachments: { title: $subTitle('attachments') },
    notIncluded: { title: $subTitle('notIncluded') },
    warningInfo: { title: $subTitle('warningInfo') },
    estimatedTimeToComplete: { title: $subTitle('estimatedTimeToComplete') },
    estimatedWorkStartAndEndAt: {
      title: $subTitle('estimatedWorkStartAndEndAt')
    },
    extraWorkConstructionPricingList: {
      title: $subTitle('extraWorkConstructionPricingList')
    },
    extraWorkTravelCosts: { title: $subTitle('extraWorkTravelCosts') },
    paymentTerms: { title: $subTitle('paymentTerms') },
    miscAgreement: { title: $subTitle('miscAgreement') },
    docIsBinding: { title: $subTitle('docIsBinding') },
    extraWorkTimeImpact: { title: $subTitle('extraWorkTimeImpact') }
  }
})

// block root node itemId -> pdfOptions prop
const hidePdfMap = {
  attachments2: 'hideDocumentAttachmentBlocks',
  answer: 'hideDocumentAnswerBlocks'
}

const blocksWithEdit = [
  'header',
  'coverBlock',
  'videoPlayer',
  'embed',
  'custom',
  'termsDisclaimer'
]

// todo update from array operation
const getComponentNameForItemType = type => {
  for (const [componentName, valueArr] of Object.entries(
    mappedComponentNames
  )) {
    if (valueArr.includes(type)) return componentName
  }
  return type
}

/**
 * Combines the data from a flat array to a flat node tree structure
 * Outputs a nested node tree
 * @param {Object} data
 * @param {Array} tree
 */
export const createNodeTreeSchema = ({
  data,
  tree,
  bindData = false,
  // eslint-disable-next-line no-unused-vars
  customProps = {},
  pdfOptions = {}
}) => {
  const hashTable = Object.create(null)

  const mappedComponentProperties = defaultComponentsProperties()

  // Add a new key to each node. children = []
  tree.forEach(node => {
    const componentName = getComponentNameForItemType(node.itemType)
    const props = get(
      mappedComponentProperties,
      `${componentName}.${node.itemId}`,
      {}
    )

    // Connect values directly to value props
    // i.e. replacing the key reference with the actual value
    // Input: `description: 'workDescription'` ===> Output: `description: 'We are going to fix...'`
    const nodeHasValue = node.value && typeof node.value === 'object'
    if (bindData && nodeHasValue) {
      Object.keys(node.value).forEach(propName => {
        props[propName] = get(data, `${node.value[propName]}`)
      })
      node._value = node.value // Store old value just incase
    }

    hashTable[node.nodeId] = {
      ...node,
      componentName,
      props,
      children: [],
      showEditButton: blocksWithEdit.includes(node.itemType)
    }
  })

  const dataTree = []
  tree.forEach(node => {
    if (node.parentId && node.parentId !== '0') {
      hashTable[node.parentId].children.push(hashTable[node.nodeId])
    } else {
      dataTree.push(hashTable[node.nodeId])
    }
  })

  // Sort nodes with placement props
  dataTree.forEach((node, index) => {
    if (node.placement && node.placement === 'last') {
      // Move to last position
      dataTree.push(dataTree.splice(index, 1)[0])
    }

    // Remove section if decided by pdf options
    if (hidePdfMap[node.itemId] && pdfOptions[hidePdfMap[node.itemId]]) {
      dataTree.splice(index, 1)
    }
  })

  return dataTree
}
/**
 * Flatten a nested template, with the option to generate nodeIds and parentIds for all its nodes
 */
export const flattenNodeTree = (arrVal, { setId = false } = {}) => {
  let currentId = 1
  const flatten = arr => {
    let result = []
    arr.forEach(a => {
      if (setId) {
        a.nodeId = currentId
        currentId++
      }
      result.push(a)
      if (a && Array.isArray(a.children) && a.children.length) {
        if (setId) {
          a.children.forEach(children => {
            children.parentId = a.nodeId
          })
        }
        result = [...result, ...flatten(a.children)]
        delete a.children
      }
    })
    return result
  }
  return flatten(arrVal)
}

export const flattenArrayByKey = (key, dataArray = []) => {
  if (!key) throw new Error('Missing param key')
  return dataArray.reduce((acc, current) => {
    const nested = current[key]
    if (Array.isArray(nested)) {
      const currentClean = current
      // delete currentClean[key];
      return acc.concat(currentClean, flattenArrayByKey(key, nested))
    }
    return acc.concat(current)
  }, [])
}

export default {
  createNodeTreeSchema,
  flattenArrayByKey
}
