import { deleteFile, getFile, patchFile, postFile, putFile } from '@cling/api'
import globalMutationTypes from '@cling/store/mutation-types'
import { filterAllowedProperties } from '@cling/utils'

import { actionTypes, mutationTypes } from './constants'

const moduleName = 'files'

const {
  LOAD_FILE,
  CREATE_FILE,
  UPDATE_FILE,
  DELETE_FILE,
  PATCH_FILE,
  FORM_NEW_FILE,
  FORM_EDIT_FILE,
  FORM_SUBMIT_FILE
} = actionTypes

const {
  UPDATE_MANY_FILES,
  DELETE_ONE_FILE,
  SET_FILES_FETCHING,
  SET_FILES_POSTING
} = mutationTypes

const { SET_FORM, CLEAR_FORM } = globalMutationTypes.forms

export default {
  /**
   * @name LOAD_FILE
   *  Load one file by id into store
   * @param {Object} Vuex object
   * @param {Object} object
   * @param {Number|String} object.id Numeric id or publicId of the file
   */
  async [LOAD_FILE]({ commit }, { id }) {
    try {
      commit(SET_FILES_FETCHING, true)
      const { data } = await getFile(id)
      commit(UPDATE_MANY_FILES, { data })
      return true
    } catch (err) {
      this.handleError(err, {
        object: 'file',
        objectId: id,
        fallbackCode: 'file.get',
        action: `${moduleName}/${LOAD_FILE}`,
        actionPayload: arguments[1]
      })
      return false
    } finally {
      commit(SET_FILES_FETCHING, false)
    }
  },
  /**
   * @name CREATE_FILE
   *  Create a new file
   * @param {Object} Vuex object
   * @param {Object} object
   * @param {Object} object.body File data
   * @returns {Promise<Number>} Promise that resolves with the new file id
   */
  async [CREATE_FILE]({ commit }, { body }) {
    try {
      commit(SET_FILES_POSTING, true)
      const fileName = body.fileName || ''
      let file = null
      if (body.blob) {
        // Create a FormData and append the file with "image" as parameter name
        const formData = new FormData()
        formData.append('file', body.blob, fileName)
        ;({ data: file } = await postFile(formData))
      } else {
        ;({ data: file } = await postFile(body))
      }
      if (!file) {
        throw new Error('No file was uploaded')
      }
      commit(UPDATE_MANY_FILES, { data: file })
      return file.id
    } catch (err) {
      this.handleError(err, {
        object: 'file',
        fallbackCode: 'file.post',
        action: `${moduleName}/${CREATE_FILE}`,
        actionPayload: arguments[1]
      })
      return null
    } finally {
      commit(SET_FILES_POSTING, false)
    }
  },
  /**
   * @name UPDATE_FILE
   *  Update a file by id
   * @param {Object} Vuex object
   * @param {Object} object
   * @param {Number|String} object.id Numeric id or public id of the file
   * @param {Object} object.body File data
   * @returns {Promise<Number>} Promise that resolves with the new file id
   */
  async [UPDATE_FILE]({ commit, dispatch }, { id, body }) {
    try {
      commit(SET_FILES_POSTING, true)
      await putFile(id, body)
      await dispatch(actionTypes.LOAD_FILE, {
        id
      })
      return id
    } catch (err) {
      this.handleError(err, {
        object: 'file',
        objectId: id,
        fallbackCode: 'file.put',
        action: `${moduleName}/${UPDATE_FILE}`,
        actionPayload: arguments[1]
      })
      return null
    } finally {
      commit(SET_FILES_POSTING, false)
    }
  },
  /**
   * @name DELETE_FILE
   *  Update a file by id
   * @param {Object} Vuex object
   * @param {Object} object
   * @param {Number|String} object.id Numeric id or public id of the file
   * @returns {Promise<Number>} Promise that resolves with the deleted file id
   */
  async [DELETE_FILE]({ commit }, { id }) {
    try {
      commit(SET_FILES_POSTING, true)
      await deleteFile(id)
      commit(DELETE_ONE_FILE, id)
      return id
    } catch (err) {
      this.handleError(err, {
        object: 'file',
        objectId: id,
        fallbackCode: 'file.delete',
        action: `${moduleName}/${DELETE_FILE}`,
        actionPayload: arguments[1]
      })
      return null
    } finally {
      commit(SET_FILES_POSTING, false)
    }
  },
  /**
   * @name PATCH_FILE
   *  Patch a file by id
   * @param {Object} Vuex object
   * @param {Object} object
   * @param {Number|String} object.id Numeric id or public id of the file
   */
  async [PATCH_FILE]({ commit, dispatch }, { id }) {
    try {
      commit(SET_FILES_POSTING, true)
      await patchFile(id)
      await dispatch(actionTypes.LOAD_FILE, {
        id
      })
      return id
    } catch (err) {
      this.handleError(err, {
        object: 'file',
        objectId: id,
        fallbackCode: 'file.patch',
        action: `${moduleName}/${PATCH_FILE}`,
        actionPayload: arguments[1]
      })
      return null
    } finally {
      commit(SET_FILES_POSTING, false)
    }
  },
  /**
   * @name FORM_NEW_FILE
   *  Prepare new file form
   * @param {Object} Vuex object
   */
  [FORM_NEW_FILE]({ commit }) {
    const formData = {
      blob: null,
      fileName: null
    }
    return commit(
      `forms/${SET_FORM}`,
      { key: 'file', formData },
      { root: true }
    )
  },
  /**
   * @name FORM_EDIT_FILE
   *  Prepare edit file form
   * @param {Object} Vuex object
   * @param {Object} object
   * @param {Number|String} object.id Numeric id or public id of the file
   * @param {String[]} object.props Optional array of strings which props to load into store
   */
  [FORM_EDIT_FILE]({ commit, state }, { id, props = [] }) {
    const formData = {
      ...filterAllowedProperties(state.data[id], props),
      id
    }
    return commit(
      `forms/${SET_FORM}`,
      { key: 'file', formData },
      { root: true }
    )
  },
  /**
   * @name FORM_SUBMIT_FILE
   *  Prepare new file form
   * @param {Object} Vuex object
   * @returns {Promise<Number|null>} Promise that resolves with the file id or null
   */
  async [FORM_SUBMIT_FILE]({ commit, rootGetters, dispatch }) {
    try {
      const fileData = rootGetters['forms/getFormByKey']('file')
      let { id } = fileData
      if (id) {
        await dispatch(UPDATE_FILE, {
          id: fileData.id,
          body: fileData
        })
      } else {
        id = await dispatch(CREATE_FILE, {
          body: fileData
        })
      }
      // Clear form
      commit(`forms/${CLEAR_FORM}`, { key: 'file' }, { root: true })
      return id
    } catch (err) {
      this.handleError(err, {
        object: 'file',
        action: `${moduleName}/${FORM_SUBMIT_FILE}`
      })
      return null
    }
  }
}
