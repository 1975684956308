import { liquidEngine } from '@cling/utils/liquid'

import get from 'lodash/get'

/*
  Custom 'templating engine' functions to support
  conversion from DOM-nodes to mustache-encased variables "{{...}}"
  and vice versa + parsing of mustache variables

  Conversion based on the TipTap editor:
  <span ...>...</span>   <-- conversions -->   {{...}}
*/

/**
 * Converts text-templates/strings (mustache) to a mention (<span>) representation
 * compatible with the TipTap rich text editor
 *
 * @param {String} string Template string to be converted
 * @param {Object} pathToLabelMap Object with data paths and UI-friendly labels for the span innerText
 * @param {Object} object Object with targeted values of the data paths
 * @param {Object} options Optional object param to specify template pre/suffix etc
 *
 * @returns {String} Where template variables matching pre/suffix will be replaced
 * by a span representation with mention-class and relevant attributes
 */
export const mustacheToSpan = (
  string,
  pathToLabelMap = {},
  object = {},
  options = {}
) => {
  const { prefix = '{{', suffix = '}}', filterDelimiter = '|' } = options || {}
  return string
    ? string.replace(
        new RegExp(`${prefix}(.*?)${suffix}`, 'g'),
        (match, offset) => {
          const [path, filter = ''] = offset.split(filterDelimiter)
          const value = pathToLabelMap[path]
          if (!value) return match
          const trueValue = get(object, path, '') || '' // Fallback empty string prevents null interpolation
          return `<span class="mention" data-mention-id="${path}" data-tooltip="${trueValue}" data-filter="${filter}">${value}</span>`
        }
      )
    : ''
}

/**
 * Converts string from <span> on TipTap form to mustache variable template
 *
 * @param {String} string Html string to convert
 *
 * @returns {String} Where spans with mention-class and data-mention-id attribute
 * are replaced with mustache representation of that data-mention-id
 */
export const spanToMustache = string =>
  typeof string !== 'string'
    ? ''
    : string.replace(
        new RegExp(
          '<span class=("|"([^"]*))mention("|([^"]*)")(.*?)([^<]+).*?</span>',
          'g'
        ),
        match => {
          let dataPath = null
          // Matches what's inside the data-mention-id attribute in the span
          match.replace(/data-mention-id="(.*?)"/g, (m, c) => {
            dataPath = c
          })

          let filter = ''
          match.replace(/data-filter="(.*?)"/g, (m, c) => {
            if (c) filter = `|${c}`
          })

          return dataPath ? `{{${dataPath + filter}}}` : match
        }
      )

/**
 * Parses string, replacing template-notation
 * with value of path within pre/suffix fetched from
 * the provided object
 * ex:
 * interpolateString('Hello {{name}}', { name: 'Foo' }) -> 'Hello Foo'
 *
 * @param {String} string Targeted string to parse
 * @param {Object} object Object containing values to use
 * @param {Object} options Optional param object to specify what pre/suffix etc to use
 *
 * @returns {String} With true template values or empty strings in place
 */
export const interpolateString = (inputString, object, options = {}) => {
  // eslint-disable-next-line no-unused-vars
  const { prefix = '{{', suffix = '}}', filterDelimiter = '|' } = options || {}
  if (typeof inputString !== 'string') return inputString

  const string = liquidEngine.parseAndRenderSync(inputString, object)
  return string
}

/**
 * Parse string, return key/path used within template-notation
 * Note: Implementation should correspond to copy in api branch
 *
 * @param {String} string Target string to parse
 * @param {Object} options Optional param to specify template characters
 *
 * @returns {Object} With used paths as keys and count as value
 */
export const getUsedPaths = (string, options = {}) => {
  if (typeof string !== 'string') return []
  const { prefix = '{{', suffix = '}}', filterDelimiter = '|' } = options || {}

  const res = {}
  string.replace(
    new RegExp(`${prefix}(.*?)${suffix}`, 'g'),
    (match, offset) => {
      const [firstPath] = offset.split(filterDelimiter)
      const path = firstPath?.trim()
      if (path) res[path] = res[path] ? res[path] + 1 : 1
    }
  )

  return res
}

/**
 * Get all used keys/paths in strings nested within an object
 * Note: Implementation should correspond to copy in api branch
 *
 * @param {Object} object Target object for string search
 * @param {Object} options Optional param to specify template interpolation characters
 *
 * @returns {Object} With all used paths as object keys and count as value
 */
export const getUsedPathsNested = (object, options = {}) => {
  let result = {}
  if (!object || typeof object !== 'object') return result

  for (const key of Object.keys(object)) {
    // Do not search strings within omitted data structures (keys)
    if (!['emailTemplate', 'smsTemplate'].includes(key)) {
      const value = object[key]
      let res
      if (value && typeof value === 'string') {
        res = getUsedPaths(value, options)
      } else if (Array.isArray(value)) {
        for (let i = 0; i < value.length; i++) {
          res = getUsedPathsNested(object[key][i])
        }
      } else {
        res = getUsedPathsNested(object[key])
      }
      result = { ...result, ...res }
    }
  }

  return result
}
