import config from '@cling/config'
import { brands } from '@cling/static'

const brand = brands[config.brand]

export default {
  brand,
  sign: 'ondertekenen',
  accept: 'accepteren',
  address: 'adres',
  and: 'en',
  answerFeedback: {
    intermediate: {
      title: 'Bedankt voor het delen van je mening.'
    },
    no: {
      start: {
        action: 'Versturen',
        placeholder: 'Wat kunnen we verbeteren?',
        text: 'Is er iets specifieks dat je wilt delen dat we kunnen verbeteren?',
        title: 'Bedankt voor het delen.'
      },
      success: {
        action: 'Lees meer over $t(brand.name)',
        secondary: 'Terug naar document',
        text: 'Je feedback is verzonden. Ons team zal je feedback beoordelen.',
        title: 'Bedankt.'
      }
    },
    start: {
      no: 'Nee',
      title: 'Beviel het om dit document te bekijken met $t(brand.name)?',
      yes: 'Ja'
    },
    yes: {
      action: 'Gratis registreren',
      secondary: 'Lees meer over $t(brand.name)',
      text: 'Maak en verstuur prachtige documenten met $t(brand.name). Ontdek alle voordelen van het verzenden van web-based documenten met elektronische handtekeningen.',
      title: 'Start gratis met $t(brand.name).'
    },
    custom: {
      title: 'Bedankt.',
      text: 'Je feedback is verzonden. Ons team zal je feedback beoordelen.',
      action: 'Terug naar document'
    }
  },
  answerModal: {
    answerSuccess: 'Je antwoord is succesvol verzonden.',
    bankId: {
      component: {
        defaultError:
          'Er ging iets mis. Zorg ervoor dat u uw burgerservicenummer correct hebt ingevoerd en probeer het opnieuw.',
        signingInterrupted: 'Tekenen onderbroken, probeer opnieuw...',
        startOnThisDevice: 'Start BankID op dit apparaat',
        validations: {
          socialNo: {
            personalNumber: 'Ongeldig burgerservicenummer',
            required: 'Voer uw burgerservicenummer in'
          }
        },
        viewText: {
          canceled: {
            text: 'Ondertekening geannuleerd. Probeer opnieuw.',
            title: 'Ondertekenen onderbroken'
          },
          error: {
            text: 'Er ging iets mis. Probeer opnieuw.',
            title: 'Er ging iets mis'
          },
          form: {
            text: 'Voer uw burgerservicenummer in om te beginnen met het ondertekenen van de overeenkomst.',
            title: 'Onderteken het document met BankID'
          },
          new: {
            spinnerText: 'Scan de QR-code op uw apparaat.',
            text: 'Open BankID op uw apparaat en onderteken de overeenkomst.',
            title: 'Onderteken het document'
          },
          signing: {
            spinnerText: 'Onderteken het document.',
            text: 'Open BankID op uw apparaat en onderteken de overeenkomst.',
            title: 'Tekenen bezig'
          },
          waiting: {
            spinnerText: 'Waiting for you to open BankID.',
            text: 'Open BankID op uw apparaat en onderteken de overeenkomst.',
            title: 'Onderteken het document'
          }
        }
      },
      start: {
        action: 'Ondertekenen met BankID',
        text: 'Accepteer en onderteken het document met BankID.',
        title: 'Ondertekenen met BankID'
      },
      success: {
        text: '$t(answerModal.successText)',
        textFallback: '$t(answerModal.successTextFallback)',
        title: '$t(answerModal.successTitle)'
      }
    },
    decline: {
      component: {
        label: 'Opmerking (optioneel)'
      },
      start: {
        action: 'Weigeren',
        text: 'Om dit document te weigeren, gelieve te bevestigen door op de onderstaande knop te klikken.',
        title: 'Document weigeren'
      },
      success: {
        text: 'Dank voor het achterlaten van een antwoord.',
        textFallback: '$t(answerModal.decline.success.text)',
        title: 'Je hebt het document geweigerd.'
      }
    },
    signature: {
      component: {
        draw: {
          canvasLabel: 'Zet handtekening',
          tab: 'Tekenen'
        },
        subTitle: 'Kies teken methode',
        terms:
          'Door dit document te ondertekenen, verklaar ik dat de elektronische handtekening een weergave is van mijn handgeschreven handtekening - en even geldig is.',
        text: {
          canvasLabel: 'Voorbeeld',
          nameLabel: 'Ondertekend door',
          namePlaceholder: 'Type je naam in',
          tab: 'Type'
        }
      },
      start: {
        action: 'Ondertekenen en accepteren',
        text: '',
        title: 'Document accepteren'
      },
      success: {
        text: '$t(answerModal.successText)',
        textFallback: '$t(answerModal.successTextFallback)',
        title: '$t(answerModal.successTitle)'
      }
    },
    versionError: {
      accept: { title: 'Kon het document niet ondertekenen' },
      decline: { title: 'Kon het document niet afwijzen' },
      text: 'Het document is gewijzigd terwijl u het bekeek. Laad het nieuwe document opnieuw en bekijk het om te antwoorden.',
      btn: 'Opnieuw laden'
    },
    successText:
      'Zodra het document door alle betrokken partijen is ondertekend, ontvangt u een e-mail naar {{email}} met een verzegelde kopie van het document.',
    successTextFallback:
      'Zodra het document door alle betrokken partijen is ondertekend, kunt u naar deze webpagina terugkeren om een verzegeld en ingevuld exemplaar van het document te downloaden.',
    successTextFallbackSingle: 'Het document is nu ondertekend en voltooid.',
    successTextSingle:
      'Er is een bevestigingsmail naar {{email}} gestuurd met een verzegelde kopie van het document.',
    successTitle: 'Je hebt het document ondertekend.'
  },
  approved: 'goedgekeurd',
  approx: 'ongeveer',
  cancel: 'Annuleren',
  chapters: {
    answerButtons: {
      approveDescription:
        'We hebben {{- sender}} laten weten dat het document is goedgekeurd.',
      approveTitle: 'Het document is goedgekeurd!',
      denyDescription:
        'We hebben {{- sender}} dat je niet geïnteresseerd bent.',
      denyTitle: 'Het document is afgewezen',
      expiredTitle: 'Het document is verlopen',
      expiredDescription:
        'De vervaldatum van het document is verstreken. Het is niet langer mogelijk om het document te ondertekenen.',
      editPropDes: 'Wijzig eigendom',
      enterPropDes: 'Voer eigendomsaanduiding in',
      inPersonSignatureHint:
        'Het is de bedoeling dat je dit document face-to-face ondertekend. Als je in plaats daarvan de digitale kopie wilt ondertekenen, neem dan contact op met',
      waitingDescription: 'Wachtend op {{remaining}} anderen voor een reactie.',
      youHave: 'Je hebt '
    },
    articleItem: {
      eligibleForDeduction: 'In aanmerking komend voor {{type}}-aftrek'
    },
    articleList: {
      changeShowVatTooltip: 'Schakelen om te tonen {{type}}',
      title: 'Diensten / Producten'
    },
    attachments: {
      emptyPlaceholder: 'Geen bijlagen toegevoegd.'
    },
    packageGroup: {
      errorCallout: 'Selecteer ten minste één optie',
      selectableOptions: 'Selecteerbare opties'
    },
    packageItem: {
      add: 'Optie toevoegen',
      select: 'Optie selecteren',
      selected: 'Geselecteerd'
    },
    parties: {
      allRiskAndLiability:
        'Heeft alle risico- en aansprakelijkheidsverzekering',
      fTax: 'Gecertificeerd voor F-tax'
    },
    pricingSummary: {
      blocks: {
        approx: 'Geschatte prijs',
        discount: 'Korting',
        deductionSubValue: 'Totale prijs zonder aftrek {{price}}',
        maxPrice: 'Max prijs',
        maxPriceVat: 'Max prijs {{vat}}',
        net: 'Netto',
        price: 'Prijs',
        rounding: 'Afronding',
        subTitleArticles: 'Voor producten / diensten met vaste kosten',
        summary: 'Samenvatting',
        total: 'Totaal',
        typeDeduction: 'Na {{type}}-aftrek'
      },
      placeholder: {
        description:
          'Kies de optie(s) die het best passen door te klikken op de ',
        descriptionBold: 'Keuzeknop',
        title: 'Selecteer een optie'
      },
      terms: {
        houseWork:
          'De totale prijs na aftrek van belastingen voor {{type}}-werkzaamheden vereist dat het uitgevoerde werk in aanmerking komt voor belastingaftrek, dat de koper voldoende aftrek beschikbaar heeft en dat het niet is gebruikt voor andere {{type}}-werkzaamheden. De koper is verantwoordelijk voor het controleren van deze informatie. \n',
        houseWorkApprox:
          'De prijs is bij benadering. Vergeet niet dat de prijs kan veranderen met maximaal 15%. Indien de diensten grond- / sloopwerkzaamheden of dergelijke omvatten. Bedenk dat de prijs kan veranderen met maximaal 25%..',
        reverseVat:
          'Let op, verlegde belastingplicht voor bouwwerkzaamheden geldt.'
      }
    },
    termsDisclaimer: {
      description: 'Door dit document goed te keuren ga je akkoord met de ',
      termsAndConditions: 'algemene voorwaarden',
      title: 'Algemene voorwaarden'
    }
  },
  close: 'Sluiten',
  company: 'bedrijf',
  deduction: {
    deduction: 'aftrek',
    type: '{{type}}-aftrek',
    manualType: 'Custom {{type}}-aftrek',
    after: 'na aftrek',
    afterType: 'na {{type}}-aftrek',
    rot: 'ROT',
    rut: 'RUT',
    greenRot15: 'Green ROT',
    greenRot20: 'Green ROT',
    greenRot50: 'Green ROT',
    rot_percentage: 'ROT',
    rut_percentage: 'RUT',
    greenRot15_percentage: 'Green ROT 15%',
    greenRot20_percentage: 'Green ROT 20%',
    greenRot50_percentage: 'Green ROT 50%'
  },
  denied: 'geweigerd',
  deny: 'Weigeren',
  denyModal: {
    denyButton: 'Document weigeren',
    helpfulInformation:
      'We zullen {{- senderName}} van {{- senderCompany}} informeren dat je het document hebt geweigerd.',
    otherPlaceholder: 'Optioneel. Laat een reactie achter waarom je weigert.',
    subTitle: 'Bevestig door op de weiger-knop te klikken.',
    successTitle: 'Je hebt het document geweigerd',
    thanksAnswer: 'Bedankt voor je reactie!',
    title: 'Document weigeren'
  },
  detail_plural: 'details',
  document: 'Document',
  document_plural: 'Documenten',
  documentDetails: {
    subTitle: 'Informatie over het document.'
  },
  documentVoided: {
    title: 'Het document is niet meer beschikbaar.',
    description:
      'De afzender heeft ervoor gekozen het document nietig te verklaren.'
  },
  documentTemplateView: {
    emailTemplate: {
      expires: {
        content:
          '$t(documentTemplateView.reminder) $t(documentTemplateView.emailTemplate.first.content)',
        subject:
          '$t(documentTemplateView.reminder) $t(documentTemplateView.sharedSubject)',
        text: '$t(documentTemplateView.emailTemplate.expires.title) $t(documentTemplateView.emailTemplate.expires.subject). $t(documentTemplateView.readMoreAndReply)\n\n{{docLink}}',
        title: '$t(documentTemplateView.emailTemplate.first.title)'
      },
      first: {
        content:
          'Je hebt een document ontvangen van {{company.name}}. Klik op de link om meer te lezen en te reageren.',
        subject: '$t(documentTemplateView.sharedSubject)',
        text: '$t(documentTemplateView.emailTemplate.first.title) $t(documentTemplateView.emailTemplate.first.subject). $t(documentTemplateView.readMoreAndReply)\n\n{{docLink}}',
        title: '$t(documentTemplateView.greetingName)'
      },
      reminder: {
        content:
          '$t(documentTemplateView.reminder) $t(documentTemplateView.emailTemplate.first.content)',
        subject:
          '$t(documentTemplateView.reminder) $t(documentTemplateView.sharedSubject)',
        text: '$t(documentTemplateView.emailTemplate.reminder.title) $t(documentTemplateView.emailTemplate.reminder.subject). $t(documentTemplateView.readMoreAndReply)\n\n{{docLink}}',
        title: '$t(documentTemplateView.emailTemplate.first.title)'
      },
      viewed: {
        content:
          '$t(documentTemplateView.reminder) $t(documentTemplateView.emailTemplate.first.content)',
        subject:
          '$t(documentTemplateView.reminder) $t(documentTemplateView.sharedSubject)',
        text: '$t(documentTemplateView.emailTemplate.viewed.title) $t(documentTemplateView.emailTemplate.viewed.subject). $t(documentTemplateView.readMoreAndReply)\n\n{{docLink}}',
        title: '$t(documentTemplateView.emailTemplate.first.title)'
      }
    },
    greetingName: 'Hallo{{clientFirstName}}!',
    readMore: 'Lees meer:',
    readMoreAndReply: 'Lees meer & reageren via:',
    reminder: 'Herinnering!',
    sharedSubject: 'Document van {{company.name}} - {{document.data.name}}',
    smsContent:
      '{{company.name}} heeft een document gestuurd waarop je kunt reageren.',
    smsTemplate: {
      expires: {
        text: '$t(documentTemplateView.reminder)\n$t(documentTemplateView.smsContent) $t(documentTemplateView.readMore)\n\n{{docLink}}'
      },
      first: {
        text: '$t(documentTemplateView.greetingName)\n$t(documentTemplateView.smsContent) $t(documentTemplateView.readMore)\n\n{{docLink}}'
      },
      reminder: {
        text: '$t(documentTemplateView.reminder)\n$t(documentTemplateView.smsContent) $t(documentTemplateView.readMore)\n\n{{docLink}}'
      },
      viewed: {
        text: '$t(documentTemplateView.reminder)\n$t(documentTemplateView.smsContent) $t(documentTemplateView.readMore)\n\n{{docLink}}'
      }
    }
  },
  email: 'email',
  enterYour: 'Voer je {{thing}} in',
  event: {
    accepted: 'Document ondertekend door {{by}}.',
    denied: 'Document geweigerd door {{by}}.',
    event_plural: 'gebeurtenissen',
    expired: 'Document verlopen.',
    requestChange: 'Document handmatig gemarkeerd als {{status}}.',
    sent: 'Document verzonden naar {{to}}.',
    statusChanged: 'Document gemarkeerd als {{status}}.',
    viewed: 'Is bekeken',
    viewedName: '{{name}} heeft het document bekeken.'
  },
  goBack: 'Ga terug',
  less: 'minder',
  login: 'Inloggen',
  logout: 'Uitloggen',
  myPages: {
    docCallout: {
      form: {
        text: 'Je kunt alle documenten bekijken die je hebt ontvangen en die aan je e-mail zijn gekoppeld via de documentenbibliotheek.',
        title: 'Documentenbibliotheek'
      },
      loginLink: 'login link',
      goToMyPagesTitle: "Ga naar je pagina's met documenten"
    },
    documentItem: {
      approved: 'Goedgekeurd',
      expired: 'Verlopen'
    },
    documentsPlaceholder: 'Je hebt (nog) geen document ontvangen.',
    pageDescription: 'Hier vind je al je ontvangen documenten.',
    requestForm: {
      alreadySentDescription:
        'Een login link werd reeds verstuurd naar {{email}}. Het kan even duren voordat je deze ontvangt.',
      alreadySentTitle: 'Login link is al verstuurd',
      emailFormat: 'Geldig formaat: naam@voorbeeld.nl',
      emailRequired: 'Voer je e-mail in',
      sendLink: 'Verstuur login link',
      sentDescription:
        'We hebben een link verstuurd naar {{email}}. Gebruik deze om in te loggen.',
      sentDescriptionSub:
        'Wegens veiligheidsredenen kun je alleen inloggen via de login link.',
      sentTitle: 'Login link verstuurd naar je inbox'
    },
    title: "Mijn pagina's"
  },
  name: 'naam',
  orgNoShort: 'KVK nummer',
  participant_plural: 'deelnemers',
  sender: 'afzender',
  phone: 'telefoon',
  price: {
    approx: 'Geschatte prijs',
    discount: 'Korting',
    fixed: 'Vaste prijs',
    markup: 'Marge',
    maxPrice: 'max prijs',
    net: 'Netto totaal',
    openAcc: 'Rekening openen',
    openAccMaxPrice: 'Rekening openen met max prijs',
    openAccPrice: 'Rekening openen',
    price: 'prijs',
    price_plural: 'prijzen',
    pricing: 'pricing',
    proposal: 'Pakket opties',
    total: 'Totaalprijs'
  },
  print: 'Print{{thing,inlineSpace}}',
  download: 'Download{{thing,inlineSpace}}',
  reference: 'referentie',
  replyBy: 'Geldig tot',
  see: 'bekijk',
  select: 'Selecteer{{thing,inlineSpace}}',
  send: 'verstuur{{thing,inlineSpace}}',
  series: {
    default: 'Document',
    document: 'Document',
    proposal: 'Offerte',
    contract: 'Contract',
    ata: 'Overeenkomst inzake naleving'
  },
  show: 'Toon{{thing,inlineSpace}}',
  socialNo: 'burgerservicenummer',
  status: {
    accepted: 'Geaccepteerd',
    active: 'Actief',
    archived: 'Gearchiveerd',
    expired: 'Verlopen',
    voided: 'Ongeldig gemaakt',
    completed: 'Voltooid',
    denied: 'Geweigerd',
    draft: 'Concept',
    new: 'Concept',
    semiSigned: 'Deels ondertekend',
    sent: 'Verstuurd',
    signed: 'Ondertekend'
  },
  summaryBottom: {
    chooseFrom: 'Kies uit selecteerbare opties',
    forDetails: 'voor details',
    noOfOptions: '{{count}}',
    option: 'optie',
    option_plural: 'opties',
    reverseVat:
      'Btw {{- amount}}. Omgekeerde belastingverplichting is van toepassing'
  },
  theDocument: 'het document',
  theSender: 'de afzender',
  times: 'tijd',
  times_plural: 'tijden',
  total: 'totaal',
  tryAgain: 'Probeer opnieuw',
  unitType: {
    unitType: 'eenheid',
    unitType_plural: 'eenheden',
    second: 's',
    minute: 'min',
    unit: 'stks',
    hour: 'h',
    m: 'm',
    m2: 'm²',
    m3: 'm³',
    kg: 'kg',
    litre: 'liters',
    day: 'dag',
    km: 'km',
    mile: 'mijl',
    month: 'mnd',
    week: 'week',
    week_plural: 'weken',
    ton: 'ton',
    year: 'jaren',
    kWh: 'kWh'
  },
  vat: {
    sharedTitle: 'Belasting',
    vat: 'Btw',
    tax: 'btw',
    exVat: 'excl. {{- thing}}',
    incVat: 'incl. {{- thing}}',
    reverseVat: 'Omgekeerde belastingverplichting',
    reverseVatApplies: 'Omgekeerde belastingverplichting is van toepassing',
    vatIdentificationNumberShort: 'Btw id.',
    rate: 'Belastingtarief'
  }
}
