import config from '@cling/config'
import { brands } from '@cling/static'

const brand = brands[config.brand]

export default {
  brand,
  sign: 'firma',
  accept: 'aceptar',
  address: 'dirección',
  and: 'y',
  answerFeedback: {
    intermediate: {
      title: 'Gracias por compartir tu opinión.'
    },
    no: {
      start: {
        action: 'Enviar',
        placeholder: '¿Qué deberíamos mejorar?',
        text: '¿Hay algo específico que te gustaría compartir que podamos mejorar?',
        title: 'Gracias por compartir.'
      },
      success: {
        action: 'Más información sobre $t(brand.name)',
        secondary: 'Volver al documento',
        text: 'Tu comentario ha sido enviado con éxito. Nuestro equipo revisará tu comentario con detenimiento.',
        title: 'Gracias.'
      }
    },
    start: {
      no: 'No',
      title: '¿Te ha gustado usar $t(brand.name) para ver este documento?',
      yes: 'Sí'
    },
    yes: {
      action: 'Regístrate gratis',
      secondary: 'Más información sobre $t(brand.name)',
      text: 'Crea y envía documentos atractivos con $t(brand.name). Descubre todas las ventajas de enviar documentos guardados en la web con firmas electrónicas.',
      title: 'Empieza a usar $t(brand.name) gratis.'
    },
    custom: {
      title: 'Gracias.',
      text: 'Tu comentario ha sido enviado con éxito. Nuestro equipo revisará tu comentario con detenimiento.',
      action: 'Volver al documento'
    }
  },
  answerModal: {
    answerSuccess: 'Tu respuesta se envió con éxito.',
    bankId: {
      component: {
        defaultError:
          'Ha habido un problema. Asegúrate de que has introducido tu número de seguridad social correctamente y vuelve a intentarlo.',
        signingInterrupted: 'Firma interrumpida, inténtalo de nuevo...',
        startOnThisDevice: 'Inicie BankID en este dispositivo',
        validations: {
          socialNo: {
            personalNumber: 'Número de seguridad social inválido',
            required: 'Introduce tu número de seguridad social'
          }
        },
        viewText: {
          canceled: {
            text: 'La firma fue cancelada. Por favor, inténtalo de nuevo',
            title: 'Firma interrumpida'
          },
          error: {
            text: 'Algo ha ido mal. Por favor, inténtalo de nuevo.',
            title: 'Se ha producido un error'
          },
          form: {
            text: 'Introduce tu número de seguridad social para empezar a firmar el acuerdo.',
            title: 'Firmar el documento con BankID'
          },
          new: {
            spinnerText: 'Escanea el código QR en tu dispositivo.',
            text: 'Abre BankID en tu dispositivo y firma el acuerdo.',
            title: 'Firmar el documento'
          },
          signing: {
            spinnerText: 'Firmar el documento.',
            text: 'Abre BankID en tu dispositivo y firma el acuerdo.',
            title: 'Firma en proceso'
          },
          waiting: {
            spinnerText: 'Esperando a que abras BankID.',
            text: 'Abre BankID en tu dispositivo y firma el acuerdo.',
            title: 'Firmar el documento'
          }
        }
      },
      start: {
        action: 'Firmar con BankID',
        text: 'Aceptar y firmar el documento con BankID.',
        title: 'Firmar con BankID'
      },
      success: {
        text: '$t(answerModal.successText)',
        textFallback: '$t(answerModal.successTextFallback)',
        title: '$t(answerModal.successTitle)'
      }
    },
    decline: {
      component: {
        label: 'Comentario (opcional)'
      },
      start: {
        action: 'Rechazar',
        text: 'Para rechazar este documento, confirma haciendo clic en el botón de abajo.',
        title: 'Rechazar documento'
      },
      success: {
        text: 'Gracias por dejar una respuesta.',
        textFallback: '$t(answerModal.decline.success.text)',
        title: 'Has rechazado el documento.'
      }
    },
    signature: {
      component: {
        draw: {
          canvasLabel: 'Dibujar la firma',
          tab: 'Dibujar'
        },
        subTitle: 'Elige el método de firma',
        terms:
          'Al firmar el documento, acepto que la firma electrónica utilizada es una representación de mi firma manuscrita y tiene la misma validez.',
        text: {
          canvasLabel: 'Vista previa',
          nameLabel: 'Firmado por',
          namePlaceholder: 'Escribe tu nombre',
          tab: 'Escribir'
        }
      },
      start: {
        action: 'Firmar y aceptar',
        text: '',
        title: 'Aceptar documento'
      },
      success: {
        text: '$t(answerModal.successText)',
        textFallback: '$t(answerModal.successTextFallback)',
        title: '$t(answerModal.successTitle)'
      }
    },
    versionError: {
      accept: { title: 'No se pudo firmar el documento' },
      decline: { title: 'No se pudo rechazar el documento' },
      text: 'El documento ha sido modificado mientras lo veía. Por favor, vuelva a cargar y revise el nuevo documento para poder responder.',
      btn: 'Recargar'
    },
    successText:
      'Una vez que el documento haya sido firmado por todas las partes implicadas, recibirás un correo electrónico a {{email}} con una copia sellada del documento.',
    successTextFallback:
      'Una vez que el documento haya sido firmado por todas las partes implicadas, puedes volver a esta página web para descargar una copia sellada y completada del documento.',
    successTextFallbackSingle: 'El documento ya se ha firmado y completado.',
    successTextSingle:
      'Se ha enviado un correo electrónico de confirmación a {{email}} con una copia sellada del documento.',
    successTitle: 'Has firmado el documento.'
  },
  approved: 'aprobado',
  approx: 'aprox.',
  cancel: 'Cancelar',
  chapters: {
    answerButtons: {
      approveDescription:
        'Le hemos informado a {{- sender}} que el documento está aprobado.',
      approveTitle: '¡El documento ha sido aprobado!',
      denyDescription:
        'Le hemos informado a {{- sender}} que no estás interesado/a.',
      denyTitle: 'El documento ha sido rechazado',
      expiredTitle: 'El documento ha expirado',
      expiredDescription:
        'La fecha de expiración del documento ha pasado. Ya no es posible firmar el documento.',
      editPropDes: 'Edita la designación de la propiedad',
      enterPropDes: 'Especifica la designación de la propiedad',
      inPersonSignatureHint:
        'Se ha establecido que debes firmar el documento en persona. Si prefieres firmar la copia digital, contacta',
      waitingDescription:
        'Esperando a que {{remaining}} otro deje una respuesta.',
      youHave: 'Tienes'
    },
    articleItem: {
      eligibleForDeduction: 'Elegible para la deducción {{type}}'
    },
    articleList: {
      changeShowVatTooltip: 'Alternar para mostrar {{type}}',
      title: 'Servicios / Productos'
    },
    attachments: {
      emptyPlaceholder: 'No se han añadido adjuntos.'
    },
    packageGroup: {
      errorCallout: 'Selecciona al menos una opción',
      selectableOptions: 'Opciones seleccionables'
    },
    packageItem: {
      add: 'Añadir opción',
      select: 'Seleccionar opción',
      selected: 'Seleccionado'
    },
    parties: {
      allRiskAndLiability:
        'Tiene seguro de todo riesgo y responsabilidad civil',
      fTax: 'Certificado para el impuesto F'
    },
    pricingSummary: {
      blocks: {
        approx: 'Precio aproximado',
        discount: 'Descuento',
        deductionSubValue: 'Precio total sin deducción {{price}}',
        maxPrice: 'Precio máximo',
        maxPriceVat: 'Precio máximo {{vat}}',
        net: 'Neto',
        price: 'Precio',
        rounding: 'Redondeo',
        subTitleArticles: 'Cuando los productos / servicios con costes fijos',
        summary: 'Resumen',
        total: 'Total',
        typeDeduction: 'Después de la deducción {{type}}'
      },
      placeholder: {
        description:
          'Elige la(s) opción(es) que más te convenga(n) haciendo clic en el ',
        descriptionBold: 'Botón de selección',
        title: 'Selecciona una opción'
      },
      terms: {
        houseWork:
          'El precio total después de la deducción de impuestos para el trabajo {{type}}requiere que el trabajo realizado cumpla los requisitos para la deducción de impuestos, que el comprador tenga una cantidad suficiente de deducción disponible y que no se haya utilizado para otro trabajo {{type}}. El comprador es responsable de verificar esta información. \n',
        houseWorkApprox:
          'El precio es un aproximado. Recuerda que el precio puede variar hasta un 15%. Si los servicios incluyen trabajos de suelo/demolición o similares. Recuerda que el precio puede variar hasta un 25%.',
        reverseVat:
          'Por favor, ten en cuenta que se aplica el impuesto inverso a las obras de construcción.'
      }
    },
    termsDisclaimer: {
      description: 'Al aprobar este documento, aceptas los ',
      termsAndConditions: 'términos y condiciones',
      title: 'Términos'
    }
  },
  close: 'Cerrar',
  company: 'empresa',
  deduction: {
    after: 'después de la deducción',
    afterType: 'después de la deducción {{type}}',
    deduction: 'deducción',
    greenRot15: 'Green ROT',
    greenRot15_percentage: 'Green ROT 15%',
    greenRot20: 'Green ROT',
    greenRot20_percentage: 'Green ROT 20%',
    greenRot50: 'Green ROT',
    greenRot50_percentage: 'Green ROT 50%',
    manualType: 'Deducción personalizada {{type}}',
    rot: 'ROT',
    rot_percentage: 'ROT',
    rut: 'RUT',
    rut_percentage: 'RUT',
    type: 'Deducción {{type}}'
  },
  denied: 'rechazado',
  deny: 'Rechazado',
  denyModal: {
    denyButton: 'Documento rechazado',
    helpfulInformation:
      'Le informaremos a {{- senderName}} de {{- senderCompany}} que has rechazado el documento.',
    otherPlaceholder:
      'OOpcional. Deja un comentario sobre el motivo por el que estás rechazando el documento.',
    subTitle: 'Confirma haciendo clic en el botón Rechazar.',
    successTitle: 'Has rechazado el documento',
    thanksAnswer: '¡Gracias por tu respuesta!',
    title: 'Rechazar documento'
  },
  detail_plural: 'detalles',
  document: 'Documento',
  document_plural: 'Documentos',
  documentDetails: {
    subTitle: 'Información sobre el documento.'
  },
  documentVoided: {
    title: 'El documento ya no está disponible.',
    description: 'El remitente ha optado por anular el documento.'
  },
  documentTemplateView: {
    emailTemplate: {
      expires: {
        content:
          '$t(documentTemplateView.reminder) $t(documentTemplateView.emailTemplate.first.content)',
        subject:
          '$t(documentTemplateView.reminder) $t(documentTemplateView.sharedSubject)',
        text: '$t(documentTemplateView.emailTemplate.expires.title) $t(documentTemplateView.emailTemplate.expires.subject). $t(documentTemplateView.readMoreAndReply)\n\n{{docLink}}',
        title: '$t(documentTemplateView.emailTemplate.first.title)'
      },
      first: {
        content:
          'Has recibido un documento de {{company.name}}. Haz clic en el enlace para leer más y dejar tu respuesta.',
        subject: '$t(documentTemplateView.sharedSubject)',
        text: '$t(documentTemplateView.emailTemplate.first.title) $t(documentTemplateView.emailTemplate.first.subject). $t(documentTemplateView.readMoreAndReply)\n\n{{docLink}}',
        title: '$t(documentTemplateView.greetingName)'
      },
      reminder: {
        content:
          '$t(documentTemplateView.reminder) $t(documentTemplateView.emailTemplate.first.content)',
        subject:
          '$t(documentTemplateView.reminder) $t(documentTemplateView.sharedSubject)',
        text: '$t(documentTemplateView.emailTemplate.reminder.title) $t(documentTemplateView.emailTemplate.reminder.subject). $t(documentTemplateView.readMoreAndReply)\n\n{{docLink}}',
        title: '$t(documentTemplateView.emailTemplate.first.title)'
      },
      viewed: {
        content:
          '$t(documentTemplateView.reminder) $t(documentTemplateView.emailTemplate.first.content)',
        subject:
          '$t(documentTemplateView.reminder) $t(documentTemplateView.sharedSubject)',
        text: '$t(documentTemplateView.emailTemplate.viewed.title) $t(documentTemplateView.emailTemplate.viewed.subject). $t(documentTemplateView.readMoreAndReply)\n\n{{docLink}}',
        title: '$t(documentTemplateView.emailTemplate.first.title)'
      }
    },
    greetingName: 'Hola{{clientFirstName}}!',
    readMore: 'Leer más:',
    readMoreAndReply: 'Leer más y responder:',
    reminder: '¡Recordatorio!',
    sharedSubject: 'Documento de {{company.name}} - {{document.data.name}}',
    smsContent:
      '{{company.name}} te ha enviado un documento al que puedes responder.',
    smsTemplate: {
      expires: {
        text: '$t(documentTemplateView.reminder)\n$t(documentTemplateView.smsContent) $t(documentTemplateView.readMore)\n\n{{docLink}}'
      },
      first: {
        text: '$t(documentTemplateView.greetingName)\n$t(documentTemplateView.smsContent) $t(documentTemplateView.readMore)\n\n{{docLink}}'
      },
      reminder: {
        text: '$t(documentTemplateView.reminder)\n$t(documentTemplateView.smsContent) $t(documentTemplateView.readMore)\n\n{{docLink}}'
      },
      viewed: {
        text: '$t(documentTemplateView.reminder)\n$t(documentTemplateView.smsContent) $t(documentTemplateView.readMore)\n\n{{docLink}}'
      }
    }
  },
  email: 'correo electrónico',
  enterYour: 'Escribe tu {{thing}}',
  event: {
    accepted: 'Documento firmado por {{by}}.',
    denied: 'Documento rechazado por {{by}}.',
    event_plural: 'eventos',
    expired: 'Documento caducado.',
    requestChange: 'Documento marcado manualmente como {{status}}.',
    sent: 'Documento enviado a {{to}}.',
    statusChanged: 'Documento marcado como {{status}}.',
    viewed: 'Ha sido visto',
    viewedName: '{{name}} ha visto el documento.'
  },
  goBack: 'Volver',
  less: 'menos',
  login: 'Iniciar sesión',
  logout: 'Cerrar sesión',
  myPages: {
    docCallout: {
      form: {
        text: 'Puedes ver todos los documentos que has recibido y que están conectados a tu correo electrónico a través de la biblioteca de documentos.',
        title: 'Biblioteca de documentos'
      },
      loginLink: 'enlace de acceso',
      goToMyPagesTitle: 'Ir a tus páginas de documentos'
    },
    documentItem: {
      approved: 'Aprobado',
      expired: 'Expirado'
    },
    documentsPlaceholder: 'No has recibido ningún documento.',
    pageDescription: 'Aquí encontrarás todos tus documentos recibidos.',
    requestForm: {
      alreadySentDescription:
        'Ya se ha enviado un enlace de acceso a {{email}}. Puede que tarde un minuto en llegar.',
      alreadySentTitle: 'El enlace de acceso ya se ha enviado',
      emailFormat: 'Formato válido: nombre@ejemplo.com',
      emailRequired: 'Introduce tu correo electrónico',
      sendLink: 'Enviar enlace de acceso',
      sentDescription:
        'Hemos enviado un enlace a {{email}}. Usa ese enlace para iniciar sesión.',
      sentDescriptionSub:
        'Por razones de seguridad, solo puedes iniciar sesión mediante el enlace de acceso.',
      sentTitle: 'Se ha enviado un enlace de acceso a tu bandeja de entrada'
    },
    title: 'Mis páginas'
  },
  name: 'nombre',
  orgNoShort: 'Número de registro de la empresa',
  participant_plural: 'participantes',
  sender: 'remitente',
  phone: 'teléfono',
  price: {
    approx: 'Precio aproximado',
    discount: 'Descuento',
    fixed: 'Precio fijo',
    markup: 'Recargo',
    maxPrice: 'Precio máximo',
    net: 'Total neto',
    openAcc: 'Cuenta abierta',
    openAccMaxPrice: 'Cuenta abierta con precio máximo',
    openAccPrice: 'Cuenta abierta',
    price: 'precio',
    price_plural: 'precios',
    pricing: 'tarifa',
    proposal: 'Opciones del paquete',
    total: 'Precio total'
  },
  print: 'Imprimir{{thing,inlineSpace}}',
  download: 'Descargar{{thing,inlineSpace}}',
  reference: 'referencia',
  replyBy: 'Responder por',
  see: 'ver',
  select: 'Seleccionar{{thing,inlineSpace}}',
  send: 'enviar{{thing,inlineSpace}}',
  series: {
    ata: 'Acuerdo de afiliación',
    contract: 'Contrato',
    default: 'Documento',
    document: 'Documento',
    proposal: 'Propuesta'
  },
  show: 'Mostrar{{thing,inlineSpace}}',
  socialNo: 'Número de la seguridad social',
  status: {
    accepted: 'Aceptado',
    active: 'Activo',
    archived: 'Archivado',
    expired: 'Caducado',
    voided: 'Anulado',
    completed: 'Completado',
    denied: 'Rechazado',
    draft: 'Borrador',
    new: 'Borrador',
    semiSigned: 'Parcialmente firmado',
    sent: 'Enviado',
    signed: 'Firmado'
  },
  summaryBottom: {
    chooseFrom: 'Elige entre las opciones seleccionables',
    forDetails: 'para más detalles',
    noOfOptions: '{{count}}',
    option: 'opción',
    option_plural: 'opciones',
    reverseVat: 'IVA {{- amount}}. Se aplica el impuesto inverso'
  },
  theDocument: 'el documento',
  theSender: 'el remitente',
  times: 'hora',
  times_plural: 'horas',
  total: 'total',
  tryAgain: 'Intentar de nuevo',
  unitType: {
    day: 'día',
    hour: 'h',
    kg: 'kg',
    km: 'km',
    kWh: 'kWh',
    litre: 'litros',
    m: 'm',
    m2: 'm²',
    m3: 'm³',
    mile: 'milla',
    minute: 'min',
    month: 'mes',
    second: 's',
    ton: 'ton',
    unit: 'uds',
    unitType: 'tipo de unidad',
    unitType_plural: 'tipos de unidad',
    week: 'semana',
    week_plural: 'semanas',
    year: 'año'
  },
  vat: {
    exVat: 'ex. {{- thing}}',
    incVat: 'incl. {{- thing}}',
    rate: 'Tipo de IVA / Impuesto',
    reverseVat: 'Impuesto inverso',
    reverseVatApplies: 'Se aplica el impuesto inverso',
    sharedTitle: 'IVA / Impuesto',
    tax: 'impuesto',
    vat: 'IVA',
    vatIdentificationNumberShort: 'Número de identificación del IVA'
  }
}
