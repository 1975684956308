/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Modified version of: https://github.com/i18next/i18next-vue/blob/v3.0.0/index.ts
 */

import type { i18n, TFunction, TOptions } from 'i18next'
import type { App, ComponentPublicInstance } from 'vue'
import { defineComponent, getCurrentInstance, reactive, ref } from 'vue'

declare module 'vue' {
  interface ComponentCustomProperties {
    $i18next: i18n
    $t: TFunction
    $tc: CountTFunction
    $formatDate: FormatDateFunction
    $formatDistanceToNow: FormatDistanceToNowFunction
  }
}

type SimpleTFunction = (key: string, options?: TOptions | string) => string
type CountTFunction = (
  key: string,
  options?: TOptions & { count?: number }
) => string
type ExistsTFunction = (key: string, options?: TOptions) => boolean
type FormatDateFunction = (
  date: Date | string,
  format: string,
  locale?: string
) => string
type FormatDistanceToNowFunction = (
  date: Date | string,
  options?: any,
  locale?: string
) => string

type ComponentI18nInstance = ComponentPublicInstance & {
  __bundles?: Array<[string, string]> // the bundles loaded by the component
  __translate?: SimpleTFunction // local to each component with an <i18n> block or i18nOptions
}
type Messages = { [index: string]: string | Messages }

declare module 'vue' {
  interface ComponentCustomOptions {
    __i18n?: string[] // due to package @intlify/vue-i18n-loader, each component with at least one <i18n> block has __i18n set
    i18nOptions?: {
      lng?: string
      keyPrefix?: string
      namespaces?: string | string[]
      messages?: Messages
    }
  }
}

interface VueI18NextOptions {
  i18next: i18n
  l10n: any
  rerenderOn?: ('languageChanged' | 'loaded' | 'added' | 'removed')[]
}

export function install(
  app: App,
  {
    i18next,
    l10n,
    rerenderOn = ['languageChanged', 'loaded', 'added', 'removed']
  }: VueI18NextOptions
): void {
  const genericT = i18next.t.bind(i18next)
  // the ref (internally) tracks which Vue instances use translations
  // Vue will automatically trigger re-renders when the value of 'lastI18nChange' changes
  const lastI18nChange = ref(new Date())
  const invalidate: () => void = () => (lastI18nChange.value = new Date())
  const usingI18n: () => void = () => lastI18nChange.value
  rerenderOn.forEach(event => {
    switch (event) {
      case 'added':
      case 'removed':
        i18next.store?.on(event, invalidate)
        break
      default:
        i18next.on(event, invalidate)
        break
    }
  })

  app.component('i18next', TranslationComponent)
  app.mixin({
    beforeCreate(this: ComponentI18nInstance) {
      const options = this.$options
      if (!options.__i18n && !options.i18nOptions) {
        this.__translate = undefined // required to enable proxied access to `__translate` in the $t function
        return
      }

      // each component gets its own 8-digit random namespace prefixed with its name if available
      const name = this.$options.name
      const rand = ((Math.random() * 10 ** 8) | 0).toString()
      const localNs = [name, rand].filter(x => !!x).join('-')

      // used to store added resource bundle identifiers for later removal upen component destruction
      this.__bundles = []
      const loadBundle = (bundle: Messages) => {
        Object.entries(bundle).forEach(([lng, resources]) => {
          i18next.addResourceBundle(lng, localNs, resources, true, false)
          this.__bundles!.push([lng, localNs])
        })
      }

      // iterate all <i18n> blocks' contents as provided by @intlify/vue-i18n-loader and make them available to i18next
      options.__i18n?.forEach(bundle => {
        loadBundle(JSON.parse(bundle))
      })

      let { lng, ns, keyPrefix } = handleI18nOptions(options, loadBundle)
      if (this.__bundles?.length) {
        // has local translations
        ns = [localNs].concat(ns ?? []) // add component-local namespace, thus finding and preferring local translations
      }

      const t = getTranslationFunction(lng, ns)
      this.__translate = (key, options) => {
        if (!keyPrefix || includesNs(key)) {
          return t(key, options)
        } else {
          // adding keyPrefix only if key is not namespaced
          return t(keyPrefix + '.' + key, options)
        }
      }
    },
    unmounted(this: ComponentI18nInstance) {
      this.__bundles?.forEach(([lng, ns]) =>
        i18next.removeResourceBundle(lng, ns)
      ) // avoid memory leaks
    }
  })

  app.config.globalProperties.$t = function (
    this: ComponentI18nInstance | undefined,
    key,
    options
  ) {
    usingI18n() // called during render, so we will get re-rendered when translations change
    if (i18next.isInitialized) {
      return (this?.__translate ?? genericT)(key, options)
    } else {
      return key
    }
  } as SimpleTFunction

  app.config.globalProperties.$tc = function (
    this: ComponentI18nInstance | undefined,
    key,
    options
  ) {
    let output = app.config.globalProperties.$t(key, options)

    if (typeof options?.count === 'number')
      output = `${options.count} ` + output

    return output
  } as CountTFunction

  app.config.globalProperties.$formatDate = function (...args) {
    return l10n.formatDate(...args)
  } as FormatDateFunction

  app.config.globalProperties.$formatDistanceToNow = function (
    date,
    options = { addSuffix: true },
    localeParam
  ) {
    let locale = localeParam
    if (!locale) locale = i18next.language === 'en' ? 'en-US' : i18next.language
    return l10n.formatDistanceToNow(date, options, locale)
  } as FormatDistanceToNowFunction

  // this proxy makes things like $i18next.language (basically) reactive
  app.config.globalProperties.$i18next = new Proxy(i18next, {
    get(target, prop) {
      usingI18n()
      return Reflect.get(target, prop)
    }
  })

  /** Translation function respecting lng and ns. The namespace can be overriden in $t calls using a key prefix or the 'ns' option. */
  function getTranslationFunction(lng?: string, ns?: string[]): TFunction {
    if (lng) {
      return i18next.getFixedT(lng, ns)
    } else if (ns) {
      return i18next.getFixedT(null, ns)
    } else {
      return genericT
    }
  }

  function includesNs(key: string): boolean {
    const nsSeparator = i18next.options.nsSeparator
    return typeof nsSeparator === 'string' && key.includes(nsSeparator)
  }

  function handleI18nOptions(
    options: any,
    loadBundle: (bundle: Messages) => void
  ) {
    let lng: string | undefined
    let ns: string[] | undefined
    let keyPrefix: string | undefined
    if (options.i18nOptions) {
      let messages: Messages | undefined
      let namespaces: string | string[] | undefined
      ;({
        lng,
        namespaces = i18next.options.defaultNS,
        keyPrefix,
        messages
      } = options.i18nOptions)

      // make i18nOptions.messages available to i18next
      if (messages) {
        loadBundle(messages)
      }

      ns = typeof namespaces === 'string' ? [namespaces] : namespaces
      if (ns) {
        i18next.loadNamespaces(ns) // load configured namespaces
      }
    }
    return { lng, ns, keyPrefix }
  }
}

export function useTranslation() {
  const instance = getCurrentInstance()
  if (!instance) {
    throw new Error(
      'i18next-vue: No Vue instance in context. Make sure to register the i18next-vue plugin using app.use(...).'
    )
  }
  const globalProps = instance.appContext.config.globalProperties
  return {
    i18next: globalProps.$i18next as i18n,
    t: globalProps.$t.bind(instance.proxy) as SimpleTFunction,
    tc: globalProps.$tc.bind(instance.proxy) as CountTFunction,
    formatDate: globalProps.$formatDate.bind(
      instance.proxy
    ) as FormatDateFunction,
    formatDistanceToNow: globalProps.$formatDistanceToNow.bind(
      instance.proxy
    ) as FormatDistanceToNowFunction
  }
}

// pattern matches '{ someSlot }'
const slotNamePattern = new RegExp('{\\s*([a-z0-9\\-]+)\\s*}', 'gi')
export const TranslationComponent = defineComponent({
  props: {
    translation: {
      type: String,
      required: true
    }
  },
  setup(props, { slots }) {
    return () => {
      const translation = props.translation
      const result = []

      let match
      let lastIndex = 0
      while ((match = slotNamePattern.exec(translation)) !== null) {
        result.push(translation.substring(lastIndex, match.index))
        const slot = slots[match[1]]
        if (slot) {
          result.push(...slot())
        } else {
          result.push(match[0])
        }
        lastIndex = slotNamePattern.lastIndex
      }
      result.push(translation.substring(lastIndex))
      return result
    }
  }
})

export default class VueI18next {
  private state: any
  i18next: i18n
  l10n: any

  constructor(i18next: i18n, l10n: any) {
    this.state = reactive({
      lang: i18next.language,
      locale: l10n.locale,
      dirty: []
    })

    this.i18next = i18next
    this.l10n = l10n
  }

  install(app: App) {
    install(app, { i18next: this.i18next, l10n: this.l10n })
    // TODO: Should move away from globals like this, included here in order to minimize side effects
    app.config.globalProperties.$i18n = this
  }

  get lang() {
    return this.state.lang
  }

  get locale() {
    return this.state.locale
  }

  isDirty(param: 'language' | 'locale') {
    return this.state.dirty.includes(param)
  }

  setDirty(v: 'language' | 'locale') {
    if (!this.state.dirty.includes(v)) {
      this.state.dirty.push(v)
    }
  }

  changeLanguage(language: string, force = false) {
    if (!this.isDirty('language') || force) {
      if (force) this.setDirty('language')
      this.i18next.changeLanguage(language)

      // Update class state to reflect underlying instance
      this.state.lang = this.i18next.language || 'en'
    }
  }

  changeLocale(locale: string, force = false) {
    if (!this.isDirty('locale') || force) {
      if (force) this.setDirty('locale')
      this.l10n.changeLocale(locale)

      // Update class state to reflect underlying instance
      this.state.locale = this.l10n.locale || 'en'
    }
  }

  t: SimpleTFunction = (key, options) => {
    return this.i18next.t(key, {
      lng: this.lang,
      ...(typeof options === 'object' ? options : {})
    })
  }

  tc: CountTFunction = (key, options) => {
    let output = this.i18next.t(key, { lng: this.lang, ...options })

    if (typeof options?.count === 'number')
      output = `${options.count} ` + output

    return output
  }

  te: ExistsTFunction = (key, options) => {
    return this.i18next.exists(key, { lng: this.lang, ...options })
  }

  formatDate: FormatDateFunction = (...args) => {
    return this.l10n.formatDate(...args)
  }

  formatDistanceToNow: FormatDistanceToNowFunction = (
    date,
    options = { addSuffix: true },
    localeParam
  ) => {
    let locale = localeParam
    if (!locale) locale = this.lang === 'en' ? 'en-US' : this.lang // map from language
    return this.l10n.formatDistanceToNow(date, options, locale)
  }
}
