import webStorage from '@cling/utils/webStorage'

import merge from 'deepmerge'
import get from 'lodash/get'
import set from 'lodash/set'

// Copied and slightly modified version of deprecated:
// https://github.com/robinvdvleuten/vuex-persistedstate

export default options => {
  const _options = options || {}
  const _key = _options.key || 'vuex'

  // We wrap default get/set in try catch to hide any error when localStorage cannot be used

  function getState(key) {
    try {
      const value = webStorage.getItem(key)
      try {
        return typeof value !== 'undefined' ? JSON.parse(value) : undefined
      } catch (err) {
        // Nothing
      }
      return undefined
    } catch (err) {
      return undefined
    }
  }

  function filter() {
    return true
  }

  function setState(key, state) {
    try {
      return webStorage.setItem(key, JSON.stringify(state))
    } catch (err) {
      return undefined
    }
  }

  function reducer(state, paths) {
    return Array.isArray(paths)
      ? paths.reduce(
          (substate, path) => set(substate, path, get(state, path)),
          {}
        )
      : state
  }

  function subscriber(store) {
    return handler => store.subscribe(handler)
  }

  const assertStorage =
    _options.assertStorage ||
    (() => {
      webStorage.setItem('@@', 1)
      webStorage.removeItem('@@')
    })

  assertStorage()

  const fetchSavedState = () => (_options.getState || getState)(_key)

  let savedState

  if (_options.fetchBeforeUse) {
    savedState = fetchSavedState()
  }

  return store => {
    if (!_options.fetchBeforeUse) {
      savedState = fetchSavedState()
    }
    if (typeof savedState === 'object' && savedState !== null) {
      store.replaceState(
        _options.overwrite
          ? savedState
          : merge(store.state, savedState, {
              arrayMerge: _options.arrayMerger || ((_, saved) => saved),
              clone: false
            })
      )
      ;(_options.rehydrated || (() => {}))(store)
    }
    // Is this correct? Shouldn't this be a separate statement?
    ;(_options.subscriber || subscriber)(store)((mutation, state) => {
      if ((_options.filter || filter)(mutation)) {
        // Same here
        ;(_options.setState || setState)(
          _key,
          (_options.reducer || reducer)(state, _options.paths)
        )
      }
    })
  }
}
