import clingapi, { putAccountType } from '@cling/api'
import eventBus from '@cling/services/eventBus'
import { global } from '@cling/store/action-types'
import globalMutationTypes from '@cling/store/mutation-types'
import { companies as companyListSchema } from '@cling/store/utils/schema'
import { filterAllowedProperties } from '@cling/utils'

import { actionTypes, mutationTypes } from './constants'
import { SET_AS_NORMALIZED_DATA } from '../../actions/constants'

const moduleName = 'companies'

const { FORM_RESET_OLD } = global

const { SET_FORM } = globalMutationTypes.forms

const {
  DO_LOAD_CURRENT_COMPANY,
  DO_NORMALIZE_COMPANIES,
  DO_UPDATE_CURRENT_COMPANY,
  FORM_EDIT_COMPANY,
  FORM_SUBMIT_COMPANY,
  FORM_NEW_COMPANY_BRANDING,
  FORM_EDIT_COMPANY_BRANDING,
  FORM_SUBMIT_COMPANY_BRANDING,
  UPDATE_COMPANY_ACCOUNT_TYPE
} = actionTypes

const { SET_COMPANIES_FETCHING, SET_COMPANIES_POSTING } = mutationTypes

export default {
  /**
   * @name DO_NORMALIZE_COMPANIES
   * Normalize companies and insert into store.
   * Used to insert companies from action, but also from API service on refresh token
   *
   * @param {Object} Vuex object
   * @param {Object} obj
   * @param {Object[]} obj.companies Array or companies
   *
   * @returns {Promise} Resolves if successfully, or throws error
   */
  async [DO_NORMALIZE_COMPANIES](_, { companies = [] }) {
    if (!companies || !Array.isArray(companies) || !companies.length)
      throw new Error('Missing param companies')
    await this.dispatch(SET_AS_NORMALIZED_DATA, {
      data: companies,
      schema: companyListSchema // 'companies',
    })
  },

  /**
   * @name DO_LOAD_CURRENT_COMPANY
   * Load company for current logged in user
   *
   * @param {Object} Vuex object
   * @returns {Object} Company for logged in user
   */
  async [DO_LOAD_CURRENT_COMPANY]({ commit, dispatch }, { emit } = {}) {
    try {
      commit(SET_COMPANIES_FETCHING, true)
      const { data: company } = await clingapi.get('/company')
      await dispatch(DO_NORMALIZE_COMPANIES, { companies: [company] })

      this.$supportChat?.syncData()

      commit(SET_COMPANIES_FETCHING, false)

      if (emit) eventBus.trigger('account:updated')

      return company
    } catch (err) {
      commit(SET_COMPANIES_FETCHING, false)
      throw err
    }
  },

  /**
   * @name DO_UPDATE_CURRENT_COMPANY
   * Submit updated company to api
   *
   * @param {Object} param Vuex object
   * @param {Object} payload
   * @param {Object} payload.body Object with updates
   *
   * @returns {Object} Current company
   */
  async [DO_UPDATE_CURRENT_COMPANY]({ dispatch, commit }, { body }) {
    try {
      commit(SET_COMPANIES_POSTING, true)
      await clingapi.put('/company', body)

      const company = await dispatch(DO_LOAD_CURRENT_COMPANY)

      commit(SET_COMPANIES_POSTING, false)
      return company
    } catch (err) {
      commit(SET_COMPANIES_POSTING, false)
      throw err
    }
  },

  /**
   * @name UPDATE_COMPANY_ACCOUNT_TYPE
   * Update authenticated company accountType
   * Period and periodCost is optional but if any of them are included, both must be sent.
   *
   * @param {Object} param Vuex object
   * @param {Object} obj
   * @param {String} obj.accountType The new accountType
   * @param {Number} obj.billingCycleCost The new billingCycleCost
   * @param {String} obj.billingCycleInterval The new billingCycleInterval
   * @param {Number} obj.billingCycleCount The new billingCycleCount
   * @param {String} obj.premiumSource Optional to specify which source upgraded to premium, client (the user themselves) or cling (cling employee)
   * @param {Number} obj.quantity Optional amount of licenses
   *
   * @returns {Promise<Boolean>} Resolves with true if successfully, otherwise false
   */
  async [UPDATE_COMPANY_ACCOUNT_TYPE](
    { dispatch, commit },
    {
      accountType,
      billingCycleCost,
      billingCycleInterval,
      billingCycleCount,
      premiumSource = null,
      quantity
    }
  ) {
    try {
      commit(SET_COMPANIES_POSTING, true)

      if (!accountType) throw new Error('Missing parameter accountType')

      if (billingCycleInterval && !billingCycleCount)
        throw new Error(
          'Param billingCycleCount is required if billingCycleInterval is used'
        )
      if (!billingCycleInterval && billingCycleCount)
        throw new Error(
          'Param billingCycleInterval is required if billingCycleCount is used'
        )
      if (
        (billingCycleInterval || billingCycleCount) &&
        typeof billingCycleCost === 'undefined'
      ) {
        throw new Error('Param billingCycleCost is missing')
      }

      const body = {
        premiumSource
      }

      if (billingCycleInterval && billingCycleCount && billingCycleCost) {
        body.billingCycleInterval = billingCycleInterval
        body.billingCycleCount = billingCycleCount
        body.billingCycleCost = billingCycleCost
      }

      if (typeof quantity !== 'undefined') body.quantity = quantity

      await putAccountType(accountType, body)

      await dispatch(DO_LOAD_CURRENT_COMPANY)

      commit(SET_COMPANIES_POSTING, false)
      return true
    } catch (err) {
      this.handleError(err, {
        object: 'companyAccount',
        action: `${moduleName}/${UPDATE_COMPANY_ACCOUNT_TYPE}`
      })
      return false
    } finally {
      commit(SET_COMPANIES_POSTING, false)
    }
  },

  /**
   * @name FORM_EDIT_COMPANY
   *  Prepare edit form
   * @param {Object} Vuex object
   * @param {Object} object optional
   * @param {String[]} object.props Optional array of strings which props to load into store
   */
  async [FORM_EDIT_COMPANY]({ rootGetters }, { props = [] } = {}) {
    try {
      const formData = filterAllowedProperties(
        rootGetters['application/company'],
        props
      )
      this.commit(`forms/${SET_FORM}`, { key: 'company', formData })
      return true
    } catch (err) {
      this.handleError(err)
      return false
    }
  },
  // Submit
  async [FORM_SUBMIT_COMPANY]({ rootGetters, dispatch }) {
    try {
      const companyData = rootGetters['forms/getFormByKey']('company')
      await dispatch(DO_UPDATE_CURRENT_COMPANY, {
        body: companyData
      })
      await this.dispatch(FORM_RESET_OLD, 'company')
      return true
    } catch (err) {
      this.handleError(err, {
        object: 'companies',
        fallbackCode: 'companies.submit',
        action: `${moduleName}/${FORM_SUBMIT_COMPANY}`
      })
      return false
    }
  },
  // CompanyBranding
  // Prepare create companyBranding
  async [FORM_NEW_COMPANY_BRANDING]({ rootGetters, dispatch }) {
    try {
      const { CompanyBranding } = rootGetters['application/company']
      // If companyBranding already exists use that one
      if (CompanyBranding && CompanyBranding.id) {
        return dispatch(FORM_EDIT_COMPANY_BRANDING)
      }
      const formData = {
        about: ''
      }
      this.commit(`forms/${SET_FORM}`, { key: 'companyBranding', formData })
      return true
    } catch (err) {
      this.handleError(err, {
        object: 'companies',
        action: `${moduleName}/${FORM_NEW_COMPANY_BRANDING}`
      })
      return false
    }
  },
  // Prepare edit companyBranding
  async [FORM_EDIT_COMPANY_BRANDING]({ rootGetters }) {
    try {
      const { CompanyBranding } = rootGetters['application/company']
      const formData = CompanyBranding
      this.commit(`forms/${SET_FORM}`, { key: 'companyBranding', formData })
      return true
    } catch (err) {
      this.handleError(err, {
        object: 'companies',
        action: `${moduleName}/${FORM_EDIT_COMPANY_BRANDING}`
      })
      return false
    }
  },
  // Prepare submit companyBranding
  async [FORM_SUBMIT_COMPANY_BRANDING]({ rootGetters, dispatch, commit }) {
    try {
      commit(SET_COMPANIES_POSTING, true)
      const data = rootGetters['forms/getFormByKey']('companyBranding')
      let { id } = data
      if (id) {
        await clingapi.put('/companyBranding', data)
      } else {
        ;({ id } = await clingapi.post('/companyBranding', data))
      }
      // Update in store
      dispatch(DO_LOAD_CURRENT_COMPANY)
      commit(SET_COMPANIES_POSTING, false)
      await this.dispatch(FORM_RESET_OLD, 'companyBranding')
      return id
    } catch (err) {
      commit(SET_COMPANIES_POSTING, false)
      this.handleError(err, {
        object: 'companies',
        fallbackCode: 'companies.submit',
        action: `${moduleName}/${FORM_SUBMIT_COMPANY_BRANDING}`
      })
      return false
    }
  }
}
