<template>
  <FileUpload
    ref="upload"
    v-model="files"
    :maximum="maximum"
    :custom-action="postAction"
    :extensions="extensions"
    :multiple="multiple"
    :drop="drop || modalOpen"
    :size="maxSize"
    :thread="1"
    :accept="accept"
    class="btn btn-primary white-list"
    @input="$emit('input', files)"
    @input-filter="inputFilter"
    @input-file="inputFile"
  >
    <slot :open-file-picker="open" />
    <div
      v-if="drop || modalOpen"
      v-show="$refs?.upload?.dropActive"
      :class="{ transparent: modalOpen }"
      class="drop-active font-inter white-list"
    >
      <CIcon type="upload-cloud" size="60" />
      {{ $t('dropActive') }}
    </div>
    <portal v-if="modalOpen" to="modal">
      <div class="modal-overlay" @click.self="modalOpen = false">
        <div class="modal-container">
          <UploadModal @open-picker="openFilePicker" @select="handleSelect" />
        </div>
      </div>
      <div
        v-if="$refs.upload?.dropActive"
        class="drop-active font-inter"
        style="pointer-events: none"
      >
        <!-- eslint-disable -->
        <component :is="'style'" type="text/css">
          * { pointer-events: none !important; } .white-list { pointer-events:
          initial !important; }
        </component>
        <!-- eslint-enable -->
        <CIcon type="upload-cloud" size="60" />
        {{ $t('dropActive') }}
      </div>
    </portal>
  </FileUpload>
</template>

<script>
import clingapi from '@cling/api'
import { snackMessage } from '@cling/services/messages'

import ImageCompressor from 'compressorjs'
import { defineAsyncComponent } from 'vue'
import FileUpload from 'vue-upload-component'

export default {
  i18nOptions: {
    namespaces: 'baseUpload',
    messages: {
      en: {
        dropActive: 'Drag & drop files to upload',
        errors: {
          thumbnail: 'Could not create thumbnail',
          compress: 'Could not upload photo',
          general: 'File could not be uploaded',
          extension:
            'Unsupported file format. Supported types are: {{extensions}}',
          size: 'File is too large. Max file size is {{maxFileSize}}',
          ECONNREFUSED: 'File could not be uploaded, no internet connection'
        }
      },
      sv: {
        dropActive: 'Släpp filer för att ladda upp',
        errors: {
          thumbnail: 'Kunde inte skapa tumnagel',
          compress: 'Kunde inte ladda upp bild',
          general: 'Kunde inte ladda upp fil',
          extension: 'Ogiltigt format. Tillåtna format: {{extensions}}',
          size: 'För stor fil. Max filstorlek är {{maxFileSize}}',
          ECONNREFUSED: 'Kunde inte ladda upp fil, ingen internetanslutning'
        }
      }
    }
  },
  name: 'BaseUpload',
  components: {
    FileUpload,
    UploadModal: defineAsyncComponent(
      () => import('@cling/components/UploadModal.vue')
    )
  },
  emits: ['input', 'upload-success', 'has-mounted'],
  props: {
    // Optional function to handle any errors
    // If not supplied a default errorHandler is used
    onError: {
      type: Function,
      default: undefined
    },
    autostartUpload: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: true
    },
    // max file size in bytes
    maxSize: {
      type: Number,
      default: 1000 * 1000 * 10 // 10 MB
    },
    // Compression (0 - 1)
    // Only for image/jpeg and image/webp
    quality: {
      type: Number,
      default: 0.8 // Be careful to use 1 as it may make the size of the output image become larger
    },
    // Resize
    maxHeight: {
      type: Number,
      default: Infinity
    },
    maxWidth: {
      type: Number,
      default: Infinity
    },
    // Thumbnail props
    createThumbnails: {
      type: Boolean,
      default: false
    },
    thumbnailMaxWidth: {
      type: Number,
      default: 120
    },
    thumbnailMaxHeight: {
      type: Number,
      default: 120
    },
    drop: {
      type: Boolean,
      default: false
    },
    maximum: {
      type: Number,
      default: null
    },
    extensions: {
      type: String,
      default: 'gif,jpg,jpeg,png,webp,heic,heif'
    },
    accept: {
      type: String,
      default: 'image/png,image/gif,image/jpeg,image/webp,image/heic,image/heif'
    },
    recentModal: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      files: [],
      modalOpen: false
    }
  },
  mounted() {
    this.$emit('has-mounted', true)
  },
  methods: {
    open() {
      if (!this.recentModal) this.openFilePicker()
      else this.modalOpen = true
    },
    openFilePicker() {
      if (this.$refs.upload)
        this.$refs.upload.$el.querySelector("input[type='file']").click()
    },
    closeRecentModal() {
      this.$modal.hide('recent-modal')
    },
    async postAction(file) {
      const formData = new FormData()
      formData.append('file', file.file, file.file.name)
      const { data } = await clingapi.post('/file', formData, {
        onUploadProgress: progressEvent => {
          const oldFiles = this.files.filter(
            currFile => currFile.id === file.id
          )
          if (oldFiles.length) {
            const oldFile = oldFiles[0]
            const newData = oldFile
            const progress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            )
            newData.progress = progress
            this.$refs.upload.update(oldFile, newData)
          }
        },
        timeout: 60000
      })
      const newData = file
      newData.response = data
      this.$refs.upload.update(file, newData)
      return data
    },
    // Pre-treatment when files are added
    inputFilter(newFile, oldFile) {
      // Create a url so we can preview any uploaded file if needed
      if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
        newFile.url = ''
        const URL = window.URL || window.webkitURL
        if (URL && URL.createObjectURL) {
          newFile.url = URL.createObjectURL(newFile.file)
        }
      }
    },
    // Method to start uploading
    startUpload() {
      this.$refs.upload.active = true
    },
    // Clear any uploads
    undoFileUpload() {
      this.$refs.upload.clear()
    },
    remove(file) {
      this.$refs.upload.remove(file)
    },
    // When a file is changed
    inputFile(newFile, oldFile) {
      if (this.modalOpen) this.modalOpen = false
      // Create thumbnail
      if (
        this.createThumbnails &&
        newFile &&
        !newFile.thumbnailId &&
        newFile.file &&
        newFile.type.substr(0, 6) === 'image/' &&
        !['image/heic', 'image/heif', 'image/tiff'].includes(newFile.type) &&
        !oldFile
      ) {
        newFile.error = 'thumbnail'
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
          try {
            const { id: thumbnailId } = await this.generateThumbnail(
              newFile.file
            )
            const newData = newFile
            newData.thumbnailId = thumbnailId
            newData.error = ''
            // Successfully deleted the error and add thumbnailId
            this.$refs.upload.update(newFile, newData)
            return resolve()
          } catch (err) {
            return reject(err)
          }
        }).catch(err => {
          // Modify the error
          this.$refs.upload.update(newFile, {
            error: err.code || err.error || err.message || err
          })
        })
      }

      // Compress and resize
      if (
        newFile &&
        newFile.file &&
        newFile.type.substr(0, 6) === 'image/' &&
        !['image/heic', 'image/heif', 'image/tiff'].includes(newFile.type) &&
        !newFile.hasCompressed
      ) {
        // Set an error to prevent being uploaded
        newFile.error = 'compressor'
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
          try {
            const compressedFile = await this.compressFile(newFile.file)
            this.$refs.upload.update(newFile, { ...compressedFile, error: '' })
            return resolve()
          } catch (err) {
            return reject(err)
          }
        }).catch(err => {
          // Modify the error
          this.$refs.upload.update(newFile, {
            error: err.code || err.error || err.message || err
          })
        })
      }

      // Autostart
      if (newFile && !newFile.active && !newFile.success && !newFile.error) {
        if (this.autostartUpload) {
          newFile.active = true
        }
      }
      // Finished upload file
      if (newFile && newFile.success && !oldFile.success) {
        this.$emit('upload-success', newFile)
      }

      // Error uploading file
      if (
        newFile &&
        newFile.error &&
        newFile.error !== 'thumbnail' &&
        newFile.error !== 'compressor' &&
        (!oldFile || !oldFile.error)
      ) {
        // Is there a prop supplied to handle errors?
        if (this.onError) {
          this.onError(newFile)
        } else {
          // Use default errorHandler
          let errorText = null
          switch (newFile.error) {
            case 'extension': {
              const humanExtensions = this.extensions.replace(/,/g, ', ') // insert space after each ,
              errorText = this.$t('errors.extension', {
                extensions: humanExtensions
              })
              break
            }
            case 'size': {
              errorText = this.$t('errors.size', {
                maxFileSize: `${this.maxSize / 1000000} MB`
              })
              break
            }
            case 'ECONNREFUSED': {
              errorText = this.$t('errors.ECONNREFUSED')
              break
            }
            default: {
              errorText = `${this.$t('errors.general')}: ${newFile.error}`
            }
          }

          // Use default errorHandler
          snackMessage('error', errorText)
        }

        // Delete the uploaded file that failed
        this.remove(newFile)
      }
      return true
    },
    async generateThumbnail(originalFile) {
      try {
        return new Promise((resolve, reject) => {
          new ImageCompressor(originalFile, {
            quality: this.quality,
            maxWidth: this.thumbnailMaxWidth,
            maxHeight: this.thumbnailMaxHeight,
            success: result => {
              const formData = new FormData()
              const newFileName = this.appendToFilename(
                result.name,
                '_thumbnail'
              )
              formData.append('file', result, newFileName)
              return clingapi
                .post('/file', formData)
                .then(apiResult => resolve(apiResult.data))
                .catch(err => reject(err))
            },
            error: err => reject(err)
          })
        })
      } catch (err) {
        snackMessage('error', this.$t('errors.thumbnail'))
        return Promise.reject(err)
      }
    },
    /**
     * Helper to compress file
     *
     * @param {Object} originalFile
     *
     * @returns {Promise} Resolves with a new fileObj when done
     */
    async compressFile(originalFile) {
      let compressor
      try {
        return new Promise((resolve, reject) => {
          compressor = new ImageCompressor(originalFile, {
            quality: this.quality,
            maxWidth: this.maxWidth,
            maxHeight: this.maxHeight,
            success: result => {
              const newData = {
                file: result,
                size: result.size,
                type: result.type,
                hasCompressed: true
              }
              return resolve(newData)
            },
            error: err => reject(err)
          })
        })
      } catch (err) {
        snackMessage('error', this.$t('errors.compress'))

        this.$refs.upload.update(originalFile, { error: err.message })
        return Promise.reject(err)
      } finally {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        compressor = null
      }
    },
    appendToFilename(filename, string) {
      const dotIndex = filename.lastIndexOf('.')
      if (dotIndex === -1) return filename + string
      return (
        filename.substring(0, dotIndex) + string + filename.substring(dotIndex)
      )
    },
    handleSelect(file) {
      this.$emit('upload-success', { response: file })
      this.modalOpen = false
    }
  }
}
</script>

<style>
/* Stylesheet needs to be defined so that the widget build can snap it up */
/* Created by the vue-upload-component library. */

.file-uploads {
  overflow: hidden;
  position: relative;
  text-align: center;
  display: inline-block;
}
.file-uploads.file-uploads-html4 input,
.file-uploads.file-uploads-html5 label {
  background: #fff;
  opacity: 0;
  font-size: 20em;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  width: 100%;
  height: 100%;
}
.file-uploads.file-uploads-html4 label,
.file-uploads.file-uploads-html5 input {
  background: rgba(255, 255, 255, 0);
  overflow: hidden;
  position: fixed;
  width: 1px;
  height: 1px;
  z-index: -1;
  opacity: 0;
}
</style>

<style scoped lang="scss">
@import '@cling/styles/main.scss';

.modal-overlay {
  background-color: rgba($black, 0.3);
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 999999999;
  display: flex;
  padding: 2em 0;
  font-size: 16px;
  overflow: auto;
}
.modal-container {
  margin: auto;
  display: flex;
  flex-wrap: nowrap;
  /* overflow: hidden; */
  cursor: default;
  position: relative;
  max-width: 90%;
  /* height: 800px; */
  /* max-height: 90%; */
  /* background-color: white; */
  /* font-size: 16px; */
  overflow: hidden;
  .modal-main {
    flex: 1 1 auto;
    height: 100%;
    width: 100%;
    position: relative;
  }
}
.drop-active {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: fixed;
  z-index: 999999999999;
  text-align: center;
  background: hsl(var(--primary-color-500) / 0.8);
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: calc(16px + 1vw);
  color: $white;
  i {
    margin-bottom: 0.5em;
    display: block;
  }
  &.transparent {
    opacity: 0;
  }
}
</style>
<style lang="scss">
.file-uploads.file-uploads {
  display: initial; // Disregard forced styling from plugin
}

.file-uploads.file-uploads-html4 input[type='file'],
.file-uploads.file-uploads-html5 label {
  cursor: pointer;
}
</style>
